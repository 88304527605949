import { create } from 'zustand'
import { request } from '../api'
import { authStore } from '../auth'
import { devtools } from 'zustand/middleware'

/**
 * For the time being we don't bother with recreating the list of permissions here for type suggestions. We should
 * probably type the set of permissions as an interface or something in the API and import it here. For now, everything
 * goes! :)
 *
 */
interface IPermissionsStore {
  list: string[]
  has: (path: string) => boolean
}

export const permissionsStore = create<IPermissionsStore>()(
  devtools(
    (set, get) => ({
      list: [],
      has: (path: string) => !!get().list.find(p => p.startsWith(path)),
    }),
    {
      enabled: true,
      name: 'permissionStore',
    }
  )
)
export const usePermissions = permissionsStore

export const init = async () => {
  const { data: list } = await request('/accounts/me/permissions')
  permissionsStore.setState({ list })

  // Make sure to wipe the permissions if no token is present.
  authStore.subscribe(
    state => state.isLoggedIn,
    async (isLoggedIn?: boolean) => !isLoggedIn && permissionsStore.setState({ list: [] })
    // state => state.isLoggedIn
  )

  return true
}
