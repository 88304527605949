import React from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import { default as AddEntityIcon } from '@mui/icons-material/AddCircleOutline'

import { HelpBox } from './components/HelpBox'
import { DataTable, FullPageDataTableContainer } from './components/DataTable'
import { DeploymentTargetsForm } from './DeploymentTargetsForm'
import { DeploymentTarget } from '../../api/app/deploymentTargets'
import { useInstance } from './state/auth/currentInstance'
import { useTranslation } from './services/i18n'

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
    },
    {
        field: 'pipeline',
        headerName: 'Pipeline',
        flex: 1,
    }
] 

export const DeploymentTargets = () => 
    <Routes>
        <Route path="/" element={<DeploymentTargetsList/>}/>
        <Route path="create/*" element={<DeploymentTargetsForm/>}/>
        <Route path=":connectedSystemId/*" element={<DeploymentTargetsForm/>}/>
    </Routes>

export const DeploymentTargetsList = () => {
    const { t } = useTranslation()
    const instanceId = useInstance(instance => instance.gsr_inst)

    return (
        <FullPageDataTableContainer>
            <HelpBox title={<Skeleton animation={false} width={500} />}>
                <Skeleton animation={false} />
                <Skeleton animation={false} />
                <Skeleton animation={false} width={600} />
            </HelpBox>

            <Box mb={3}>
                <Button 
                    to="create"
                    color="primary" 
                    component={Link}
                    variant="contained" 
                    startIcon={<AddEntityIcon/>}>
                    {t('add-new-target')}
                </Button>
            </Box>
            
            <DataTable<DeploymentTarget>
                columns={columns}
                endpoint={`/deployment-targets`}
                getRowId={record => record.gsr_tgt}
                getEditPath={(record) => record.gsr_tgt}
                requestOptions={{ queryParameters: { gsr_inst: instanceId } }}/>
        </FullPageDataTableContainer>
    )
}