import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'

import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'

import { request } from '../state/api'
import useEvent from 'react-use-event-hook'
import { TreeView } from '@grapecity/wijmo.react.nav'
import { Menu, MenuItem, MenuSeparator } from '@grapecity/wijmo.react.input'
import * as wjCore from '@grapecity/wijmo'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  TextField,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { IoDuplicateSharp } from 'react-icons/io5'
import { IoMdMove } from 'react-icons/io'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import FormatShapesOutlinedIcon from '@mui/icons-material/FormatShapesOutlined'
import { useTranslation } from '../services/i18n'
import { DesignerReport } from './DesignerReport'
import { DesignerDashboard } from './DesignerDashboard'
import { ViewerReport } from './ViewerReport'
import { ViewerDashboard } from './ViewerDashboard'
import { Field, FormContent, Label } from '../components/Form'
import { handleEnterKeyDown } from '../utils/reportUtils'

export type Report = {
  id: string
  name: string
  group: string
  isDashboard: boolean
  template: any
  gsr_client: string | undefined
  gsr_inst: string
  items?: Report[]
  isFolder?: boolean
}

type IconPortalProps = {
  container: HTMLElement
  icon: React.ReactElement
}

const IconPortal: React.FC<IconPortalProps> = ({ container, icon }) => {
  return ReactDOM.createPortal(icon, container)
}

export const sanitizeAndFormatGroup = (value: string): string => {
  // Trim the input
  let transformedValue = value.trim()

  // Remove all spaces
  transformedValue = transformedValue.replace(/\s+/g, '')

  // Remove invalid characters (only allow letters, digits, and dots)
  transformedValue = transformedValue.replace(/[^a-zA-Z0-9.]/g, '')

  // Replace multiple consecutive dots with a single dot
  transformedValue = transformedValue.replace(/\.{2,}/g, '.')

  // Remove leading and trailing dots
  transformedValue = transformedValue.replace(/^\.+|\.+$/g, '')

  // Capitalize the first letter after each dot
  transformedValue = transformedValue.replace(/(?:^|\.)\w/g, match => match.toUpperCase())

  return transformedValue
}

export const getVariables = (
  Stimulsoft: any,
  reportRef: any,
  client: any,
  instance: any,
  user: any
) => {
  const variables = [
    {
      name: 'Client',
      value: client.gsr_client,
      type: Stimulsoft.System.Guid,
      description:
        "The UUID identifier of the client environment. All records available to the report have the provided value as the report cannot access other client's metadata.",
    },
    {
      name: 'ClientName',
      value: client.name,
      type: String,
      description:
        'The name of the client as defined in Flow.BI. This name identifies the client to the end-user.',
    },
    {
      name: 'Instance',
      value: instance.gsr_inst,
      type: Stimulsoft.System.Guid,
      description:
        'The UUID identifier of the instance. The report has access to all instances where the user account has access to due to the set permissions. A report therefore has to filter for the instance to provide a meaningful report for an instance.',
    },
    {
      name: 'InstanceName',
      value: instance.name,
      type: String,
      description:
        'The name of the instance as defined in Flow.BI. This name identifies the instance to the end-user.',
    },
    {
      name: 'AccountId',
      value: user.id,
      type: Stimulsoft.System.Guid,
      description:
        'The UUID to identify the user account of the currently logged in user.The UUID to identify the user account of the currently logged in user.',
    },
    {
      name: 'AccountPerspective',
      value: user.perspective,
      type: String,
      description: 'The perspective of the current user account: either business or technical.',
    },
    {
      name: 'AccountDbUser',
      value: user.database_user,
      type: String,
      description:
        'The database-level user name of the currently logged in user, which is part of the SQL key generated in Flow.BI to access the SQL API.',
    },
    {
      name: 'AccountName',
      value: user.name,
      type: String,
      description: 'The full name of the user the currently logged in user account belongs to.',
    },
    {
      name: 'AccountEmail',
      value: user.email,
      type: String,
      description: 'The Email address of the currently logged in user account.',
    },
    {
      name: 'FolderName',
      value: reportRef.current?.group,
      type: String,
      description: 'The folder name of the dashboard',
    },
  ]
  return variables
}

const languages = [
  { path: '/reports-locale/en.xml', language: 'English' },
  { path: '/reports-locale/de.xml', language: 'Deutch' },
  { path: '/reports-locale/fr.xml', language: 'French' },
  { path: '/reports-locale/ar.xml', language: 'Arabic' },
  { path: '/reports-locale/be.xml', language: 'Belarusian' },
  { path: '/reports-locale/cs.xml', language: 'Czech' },
  { path: '/reports-locale/es.xml', language: 'Spanish' },
  { path: '/reports-locale/fa.xml', language: 'Farsi' },
  { path: '/reports-locale/id.xml', language: 'Indonesian' },
]

export const ReportDashboard = () => {
  const { section } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { id, type } = Object.fromEntries(queryParams.entries())

  const [renameDialogIsOpen, setRenameDialogIsOpen] = useState(false)
  const [rename, setRename] = useState({ id: '', name: '', type: '' })
  const [groupDialogIsOpen, setGroupDialogIsOpen] = useState(false)
  const [move, setMove] = useState({ id: '', group: '', type: '' })
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState<(Report & { items: Report[] })[]>([])
  const [lastFolder, setLastFolder] = useState<Report | null>(null)

  const navigate = useNavigate()

  const fetchReports = async (): Promise<Report[]> => {
    setLoading(true)
    const { data } = await request('/reports')
    console.log('Data Fetched: ', data)
    setLoading(false)
    return data
  }

  const handleRemove = async (reportID: string) => {
    const { data } = await request(`/reports/${reportID}`, {
      method: 'delete',
      queryParameters: {},
    })

    console.log('deleted data: ', data)

    // If opened report is deleted than navigate after delete to update UI
    if (reportID === id) {
      navigate('/report')
    }
    fetchReports().then(data => {
      const treeData = buildTree(data)
      setItems(treeData)
    })
  }

  const handleDeleteGroup = async (group: string) => {
    console.log(`/reports/group/${group}`)
    const { data } = await request(`/reports/group/${group}`, {
      method: 'delete',
      queryParameters: {},
    })

    console.log('data', data)
    updateSidebar()
  }

  const buildTree = (data: Report[]) => {
    const map: { [key: string]: Report & { items: Report[] } } = {}
    const roots: (Report & { items: Report[] })[] = []

    // Initialize the map with all items
    data.forEach(item => {
      map[item.id] = { ...item, items: [] }
    })

    // Build the tree structure
    data.forEach(item => {
      if (item.group !== undefined) {
        const pathSegments = item.group.split('.')
        if (item.group === 'root') {
          // Add reports with "root" group directly to the root node
          roots.push(map[item.id])
        } else {
          let parentPath = pathSegments[0]
          let parent = roots.find(rootItem => rootItem.group === parentPath)

          // Create the root folder if it doesn't exist
          if (!parent) {
            parent = {
              id: parentPath,
              group: parentPath,
              name: parentPath,
              isDashboard: false,
              template: {},
              gsr_client: undefined,
              gsr_inst: '',
              items: [],
              isFolder: true, // Mark as folder
            }
            roots.push(parent)
          }

          // Traverse the path segments to find or create parent nodes
          for (let i = 1; i < pathSegments.length; i++) {
            const segment = pathSegments.slice(0, i + 1).join('.')
            let child: any = parent?.items.find(childItem => childItem.group === segment)

            if (!child) {
              // Create a new parent node if it doesn't exist
              child = {
                id: segment,
                group: segment,
                name: segment.split('.').pop() || '',
                isDashboard: false,
                template: {},
                gsr_client: undefined,
                gsr_inst: '',
                items: [],
                isFolder: true, // Mark as folder
              }
              if (parent) {
                parent.items.push(child)
              }
            }

            parent = child
          }

          // Add the current item to the final parent node
          map[item.id].isFolder = false // Mark as report
          if (parent) {
            parent.items.push(map[item.id])
          } else {
            console.warn(`Parent is undefined for item with id: ${item.id}`)
          }
        }
      } else {
        console.warn(`Group is undefined for item with id: ${item.id}`)
      }
    })

    // Recursively sort the tree structure
    const sortTree = (nodes: Report[]) => {
      nodes.sort((a, b) => a.name.localeCompare(b.name))
      nodes.forEach(node => {
        if (node.items) {
          sortTree(node.items)
        }
      })
    }

    sortTree(roots)
    return roots
  }

  useEffect(() => {
    updateSidebar()
  }, [])

  const handleRename = async () => {
    const { data } = await request(`/reports/rename/${rename.id}`, {
      method: 'put',
      jsonBody: { name: rename.name },
      queryParameters: {},
    })

    console.log('Renamed report: ', data)
    setRenameDialogIsOpen(false)

    updateSidebar()
  }

  const handleMove = async () => {
    const { data } = await request(`/reports/${move.id}`, {
      method: 'put',
      jsonBody: { group: move.group },
      queryParameters: {},
    })

    console.log('Moved: ', data)
    setGroupDialogIsOpen(false)
    updateSidebar()
  }

  const updateSidebar = useCallback(() => {
    fetchReports().then(data => {
      const treeData = buildTree(data)
      setItems(treeData)
    })
  }, [])

  const nudgeMessage = (
    <div
      style={{
        display: 'flex',
        color: 'white',
        width: '100%',
        backgroundColor: '#555',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
      }}>
      Select &nbsp; <SummarizeOutlinedIcon /> &nbsp; Report or &nbsp;
      <DashboardOutlinedIcon /> &nbsp; Dashboard from the left tree
    </div>
  )

  const renderSection = () => {
    switch (section) {
      case 'designer':
        switch (type) {
          case 'report':
            return <DesignerReport id={id} updateSidebar={updateSidebar} />
          case 'dashboard':
            return <DesignerDashboard id={id} updateSidebar={updateSidebar} />
          default:
            return nudgeMessage
        }
      case 'viewer':
        switch (type) {
          case 'report':
            return <ViewerReport id={id} />
          case 'dashboard':
            return <ViewerDashboard id={id} />
          default:
            return nudgeMessage
        }
      default:
        return nudgeMessage
    }
  }

  const treeViewRef = useRef<any>(null)
  const menuRef = useRef<{
    show: (e: MouseEvent) => void
    selectedValue?: string
    itemClicked: string
  } | null>(null)

  const groupMenuRef = useRef<{
    show: (e: MouseEvent) => void
    selectedValue?: string
  } | null>(null)

  const setCurrentItem = useCallback(() => {
    if (treeViewRef.current && items.length > 0) {
      const findItemById = (items: any[], id: string): any => {
        for (const item of items) {
          if (item.id === id) return item
          if (item.items) {
            const found = findItemById(item.items, id)
            if (found) return found
          }
        }
        return null
      }

      const firstItemInLastFolder = lastFolder?.items?.find(item => !item.isFolder)
      if (firstItemInLastFolder) {
        console.log('firstItemInLastFolder: ', firstItemInLastFolder)
        const selectedItem = findItemById(items, firstItemInLastFolder.id)
        if (selectedItem) {
          treeViewRef.current.selectedItem = selectedItem
          console.log('treeViewRef.current.selectedItem: ', treeViewRef.current.selectedItem)
        }
      }
    }
  }, [lastFolder, items])

  useEffect(() => {
    setCurrentItem()
  }, [setCurrentItem])

  const initialized = (control: any) => {
    treeViewRef.current = control

    setCurrentItem()

    // Custom context menu setup
    control.hostElement.addEventListener('contextmenu', (e: MouseEvent) => {
      e.preventDefault()
      const item: Report = control.selectedItem
      console.log('Selected item:', item)

      if (item && item.isFolder) {
        // Show group context menu
        if (groupMenuRef.current) {
          groupMenuRef.current.show(e)
        }
      } else {
        // Show item context menu
        if (menuRef.current) {
          menuRef.current.show(e)
        }
      }
    })

    // Property setup
    var prps =
        'allowDragging,autoCollapse,expandOnClick,isAnimated,isReadOnly,showCheckboxes'.split(','),
      host = document.getElementById('properties'),
      tpl = '<label><input id="{prop}" type="checkbox"> {prop}</label>'
    prps.forEach(prop => {
      if (host) {
        const el = wjCore.createElement(tpl.replace(/\{prop\}/g, prop), host)
        const input = el.querySelector('input')
        if (input) {
          input.checked = treeViewRef.current[prop]
        }
      }
    })
    if (host) {
      host.addEventListener('click', e => {
        if (e.target instanceof HTMLInputElement) {
          treeViewRef.current[e.target.id] = e.target.checked
        }
      })
    }
  }

  const onMenuInitialized = useEvent(sender => {
    menuRef.current = sender
  })

  const menuItemClick = async () => {
    if (menuRef.current) {
      const selectedItem: Report = treeViewRef.current.selectedItem
      if (!selectedItem) {
        console.warn('No report selected')
        return
      }
      if (menuRef.current?.selectedValue === 'edit') {
        console.log('edit is pressed', selectedItem.id)
        navigate(
          selectedItem.isDashboard
            ? `/reports/designer?type=dashboard&id=${selectedItem.id}`
            : `/reports/designer?type=report&id=${selectedItem.id}`
        )
      }
      if (menuRef.current?.selectedValue === 'rename') {
        console.log('rename is pressed')
        setRenameDialogIsOpen(true)
        setRename({
          id: selectedItem.id,
          name: selectedItem.name,
          type: selectedItem.isDashboard ? 'Dashboard' : 'Report',
        })
      }
      if (menuRef.current?.selectedValue === 'duplicate') {
        console.log('duplicate is pressed', selectedItem.id)
        const { data } = await request(`/reports/duplicate/${selectedItem.id}`, {
          method: 'post',
          queryParameters: {},
        })
        console.log('Report is duplicated: ', data)
        updateSidebar()
      }
      if (menuRef.current?.selectedValue === 'move') {
        console.log('move is pressed', selectedItem.id)
        setMove({
          id: selectedItem.id,
          group: selectedItem.group,
          type: selectedItem.isDashboard ? 'Dashboard' : 'Report',
        })
        setGroupDialogIsOpen(true)
      }
      if (menuRef.current?.selectedValue === 'delete') {
        console.log('delete is pressed', selectedItem.id)
        handleRemove(selectedItem.id)
      }
    }
  }

  const onItemClicked = useEvent(e => {
    if (!e.selectedItem.isFolder) {
      navigate(
        e.selectedItem.isDashboard
          ? `/reports/viewer?type=dashboard&id=${e.selectedItem.id}`
          : `/reports/viewer?type=report&id=${e.selectedItem.id}`
      )
    } else {
      setLastFolder(e.selectedItem)
    }
  })

  const onGroupMenuInitialized = (sender: any) => {
    groupMenuRef.current = sender
  }

  const groupMenuItemClick = () => {
    const selectedItem = treeViewRef.current.selectedItem
    if (!selectedItem) {
      console.warn('No group selected')
      return
    }

    if (groupMenuRef.current?.selectedValue === 'delete') {
      console.log('delete group is pressed')
      handleDeleteGroup(selectedItem.id)
    }
  }

  const formatItem = (s: any, e: any) => {
    const item = e.dataItem

    if (item.isFolder) return

    const icon = item.isDashboard ? (
      <DashboardOutlinedIcon fontSize="small" />
    ) : (
      <SummarizeOutlinedIcon fontSize="small" />
    )

    const iconContainer = document.createElement('span')
    // iconContainer.style.backgroundColor = 'gray'
    iconContainer.style.width = '24px'
    iconContainer.style.height = '24px'
    iconContainer.style.display = 'inline-block' // Use inline-block
    iconContainer.style.verticalAlign = 'middle' // Align vertically with text
    iconContainer.style.marginRight = '2px' // Add some space between the icon and the text

    // Insert the icon container before the text
    e.element.insertBefore(iconContainer, e.element.firstChild)

    // Use IconPortal to render the icon
    createRoot(iconContainer).render(<IconPortal container={iconContainer} icon={icon} />)
  }

  return (
    <>
      <div style={{ display: 'flex', gap: 10, height: '100%' }}>
        {/* Sidebar */}
        <div
          style={{
            padding: 10,
            minWidth: 270,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            gap: 25,
            justifyContent: 'space-between',
            backgroundColor: 'white',
            position: 'relative',
          }}>
          {/* New Report and New Dashboard Buttons */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
            }}>
            <Link to="/reports/designer?type=report&id=new">
              <Button color="primary" variant="contained" size="small" fullWidth>
                <SummarizeOutlinedIcon fontSize="small" /> &nbsp; New Report
              </Button>
            </Link>
            <Link to="/reports/designer?type=dashboard&id=new">
              <Button color="primary" variant="outlined" size="small" fullWidth>
                <DashboardOutlinedIcon fontSize="small" /> &nbsp; New Dashboard
              </Button>
            </Link>
          </div>

          {/* Wijmo TreeView */}
          <div style={{ flexGrow: 1, height: 'auto', overflow: 'auto' }}>
            {loading ? (
              <div style={{ textAlign: 'center' }}>Loading ...</div>
            ) : (
              <TreeView
                ref={treeViewRef}
                itemsSource={items}
                displayMemberPath="name"
                childItemsPath="items"
                initialized={initialized}
                itemClicked={onItemClicked}
                formatItem={formatItem}></TreeView>
            )}
            <Menu
              itemClicked={menuItemClick}
              initialized={onMenuInitialized}
              style={{ display: 'none' }}>
              <MenuItem value="edit">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <EditIcon fontSize="small" /> &nbsp; Edit
                </div>
              </MenuItem>
              <MenuItem value="rename">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <FormatShapesOutlinedIcon fontSize="small" /> &nbsp; Rename
                </div>
              </MenuItem>
              <MenuItem value="duplicate">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <IoDuplicateSharp fontSize="large" /> &nbsp; Duplicate
                </div>
              </MenuItem>
              <MenuItem value="move">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <IoMdMove fontSize="large" /> &nbsp; Move
                </div>
              </MenuItem>
              <MenuSeparator></MenuSeparator>
              <MenuItem value="delete">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <DeleteForeverIcon fontSize="small" /> &nbsp; Delete
                </div>
              </MenuItem>
            </Menu>
            <Menu
              itemClicked={groupMenuItemClick}
              initialized={onGroupMenuInitialized}
              style={{ display: 'none' }}>
              {/* <MenuItem value="delete">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <DeleteForeverIcon fontSize="small" /> &nbsp; Delete Folder
              </div>
            </MenuItem> */}
            </Menu>
          </div>

          {/* Collapse and Expand Buttons */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 6,
              justifyContent: 'center',
              bottom: 20,
              width: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 0px 20px lightblue',
              zIndex: 999,
            }}>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              fullWidth
              onClick={() => treeViewRef.current.collapseToLevel(0)}>
              Collapse
            </Button>

            <Button
              color="primary"
              variant="outlined"
              size="small"
              fullWidth
              onClick={() => treeViewRef.current.collapseToLevel(1000)}>
              Expand
            </Button>
          </div>
        </div>
        {!section ? nudgeMessage : renderSection()}
      </div>
      {/* Dialog - Rename report */}
      <Dialog
        onClick={() => {}} // prevents default behaviour of closing Dialog when clicked on backdrop
        open={renameDialogIsOpen}
        onKeyDown={e => handleEnterKeyDown(e, handleRename)}
        onClose={() => setRenameDialogIsOpen(false)}>
        <DialogTitle>Rename {rename.type}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: '3em' }}>Change name</DialogContentText>
          <FormContent>
            <Label htmlFor="name">Rename</Label>
            <Field>
              <TextField
                autoFocus
                id="name"
                type="text"
                hiddenLabel
                value={rename.name}
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                onChange={e => setRename(prev => ({ ...prev, name: e.target.value }))}
              />
            </Field>
          </FormContent>
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button variant="outlined" onClick={() => setRenameDialogIsOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleRename}>
              Rename {rename.type}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {/* Dialog - Move report */}
      <Dialog
        onClick={() => {}} // prevents default behaviour of closing Dialog when clicked on backdrop
        open={groupDialogIsOpen}
        onKeyDown={e => handleEnterKeyDown(e, handleMove)}
        onClose={() => setGroupDialogIsOpen(false)}>
        <DialogTitle>Move {move.type}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: '3em' }}>
            Move to a different folder
          </DialogContentText>
          <FormContent>
            <Label htmlFor="name">Folder</Label>
            <Field>
              <TextField
                autoFocus
                id="name"
                type="text"
                hiddenLabel
                value={move.group}
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                onChange={e => setMove(prev => ({ ...prev, group: e.target.value }))}
                onBlur={e =>
                  setMove(prev => ({ ...prev, group: sanitizeAndFormatGroup(e.target.value) }))
                }
              />
            </Field>
          </FormContent>
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button variant="outlined" onClick={() => setGroupDialogIsOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleMove}>
              Move
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const StimulsoftLicenseKey =
  '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHnqzEyCXjn66MqXS5wxwumgToDoAQ9A7X0S3VlwTKBTxedhqeBHjG3UQRbccj3FwFjJ+Lnvo6PVg6SaVcIbSy9alThgJF2N+51tkUJHf4IML87wcEVdJ6TG/gIsC3fXwSBOjQ+NWiQd8TrAY7N5D2v/180BMOS45SBR1aJIUwfl9rttsZYNEahTDNZD8brBn3t+4VtNGXdBiKnMmoBixGFDTlZzz3HFZNEo1To6nYNN1/SR25R8SfPdE3YAl9SDM+IQg6NgXfuLkW/OsLZG1V4Zp3Cypbt2LPfEnHYfJ9C1XE3MDDUx//hts9b+O4P1iLJkGwWGXBjd2A74j/9VZYEmpXQxH0bWsPF3RN3b+cxgkkkf7927SAqlygMz6+Da8qs+mMhFjwuQDcrN9YxCpTRbE9H6FMBAznT4Y5wUK9Rni9rzAFQVYcg3CSe1AtlXorVUwPndpVZhnaI6VkVvbYT3u5OaC6Bb0Zb0kBzmbvhrIV/zc9+ZkvUsm+wEKNrNEt0Y67H5aJ3S6ZgJP/dS8+8s'
