import React, { useRef } from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid'
import { default as AddEntityIcon } from '@mui/icons-material/AddCircleOutline'

import { HelpBox } from '../components/HelpBox'
import { DataTable, FullPageDataTableContainer } from '../components/DataTable'
import { Breadcrumbs } from './Breadcrumbs'
import { ApiKey } from '../../../api/app/apiKeys'
import { ApiKeyForm } from './ApiKeysForm'
import { useTranslation } from '../services/i18n'
import { useUser } from '../state/auth/currentUser'

export const ApiKeys = () => 
    <Routes>
        <Route path="/" element={<ApiKeyList/>}/>
        <Route path="create/*" element={<ApiKeyForm/>}/>
        <Route path=":apiKeyId/*" element={<ApiKeyForm/>}/>
    </Routes>

export const ApiKeyList = () => {   
    const { t } = useTranslation()

    const userName = useUser((user) => user.name)

    const columns = useRef<any>()

   if (!columns.current) {
        columns.current = [
            {
                field: 'label',
                headerName: t('label'),
                flex: 1,
            },
            {
                field: 'prefix',
                headerName: t('prefix'),
                flex: .2,
            },
            {
                field: 'access_type',
                headerName: t('access-type'),
                valueGetter: (params: GridValueGetterParams) => {
                    if (params.row.has_sql_access)
                        return t('sql-api')
        
                    if (params.row.has_rest_access)
                        return t('rest-api')
                }
            },
            {
                field: 'last_used_at',
                headerName: t('last-used-at'),
                flex: .3,
                valueFormatter: (params: GridValueFormatterParams<string>) => {
                    if (params.value == null) return t('never')
        
                    return new Date(params.value).toLocaleString()
                }
            }
        ]
    }

    return (
        <FullPageDataTableContainer>
            <Breadcrumbs>
                <Typography color="text.secondary">{userName}</Typography>
                <Typography color="text.primary">{t('api-keys')}</Typography>
            </Breadcrumbs>

            <HelpBox url="/accounts/api-keys" title={t('hints-api-keys-title')}>
                {t('hints-api-keys-content')}
                <pre>
                    <code style={{ display: 'block', whiteSpace: 'pre', backgroundColor: 'rgba(0,0,0,.1)', padding: '5px' }}>
                        curl -X 'GET' '/instances' -H 'x-api-key: 7b5w2g.4oiVl6M4HQN2oCGVoxNsaaXfMr_T0UGi'
                    </code>
                </pre>
                {t('hints-api-keys-security-warning')}
            </HelpBox>

            <Box mb={3}>
                <Button 
                    to="create"
                    color="primary" 
                    component={Link}
                    variant="contained" 
                    startIcon={<AddEntityIcon/>}>
                    {t('create-api-key')}
                </Button>
            </Box>
            
            <DataTable<ApiKey> 
                columns={columns.current}
                endpoint="/api-keys"
                getEditPath={(record) => `/settings/api-keys/${record.id}`}/>
        </FullPageDataTableContainer>
    )
}