import React from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import { default as AddEntityIcon } from '@mui/icons-material/AddCircleOutline'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { GridValueFormatterParams } from '@mui/x-data-grid'

import { useTranslation } from './services/i18n'
import { HelpBox } from './components/HelpBox'
import { DataTable, FullPageDataTableContainer } from './components/DataTable'
import { Account } from '../../api/app/accounts'
import { useInstance } from './state/auth/currentInstance'
import { InformationFlowEditor } from './InformationFlowEditor'

const columns = [
    {
        field: 'label',
        headerName: 'Name',
        flex: 1,
    },
    {
        field: 'created_at',
        headerName: 'Erzeugt',
        flex: 0.3,
        valueFormatter: (params: GridValueFormatterParams<string>) => {
            if (params.value == null) return ''

            return new Date(params.value).toLocaleString()
        }
    }
]


export const InformationFlows = () => 
    <Routes>
        <Route path="/" element={<FlowsList/>}/>
        <Route path="create" element={<InformationFlowEditor/>}/>
        <Route path=":processId" element={<InformationFlowEditor/>}/>
    </Routes>

export const FlowsList = () => {
    const { t } = useTranslation()
    const instanceId = useInstance(instance => instance.gsr_inst)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = !!anchorEl
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)

    return (
        <FullPageDataTableContainer>
            <HelpBox 
                url="/information-flows"
                title={<Skeleton animation={false} width={500} />}>
                <Skeleton animation={false} />
                <Skeleton animation={false} />
                <Skeleton animation={false} width={600} />
            </HelpBox>

            <Box mb={3}>
                <Button 
                    id="new-flow-button"
                    aria-controls={open ? 'new-flow-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    color="primary" 
                    variant="contained" 
                    startIcon={<AddEntityIcon/>}
                    endIcon={<ArrowDropDownIcon/>}>
                    {t('create-new-information-flow')}
                </Button>
                <Menu
                    id="new-flow-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                    transformOrigin={{vertical: 'top', horizontal: 'right',}}
                    MenuListProps={{ 'aria-labelledby': 'new-flow-button', }}>
                    <MenuItem component={Link} onClick={handleClose} to="create">Business Entity</MenuItem>
                    <MenuItem component={Link} onClick={handleClose} to="create">French President</MenuItem>
                    <MenuItem component={Link} onClick={handleClose} to="create">Baguette</MenuItem>
                </Menu>
            </Box>
            
            <DataTable<Account>
                columns={columns}
                endpoint={`/information-flows`}
                getEditPath={(record) => `/information-flows/${record.id}`}
                requestOptions={{ queryParameters: { instanceId } }}/>
        </FullPageDataTableContainer>
    )
}