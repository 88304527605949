import { useEffect } from 'react'
import i18n, { TOptions } from 'i18next'
import { initReactI18next, useTranslation as useOriginalTranslation } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import EN from '../locales/en.json'
import { useUser } from '../state/auth/currentUser'

const resources = {
    en: { translation: EN },
}

export default i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            // We don't need this, as react is already escaping everything automatically.
            escapeValue: false,
        },
        debug: false,
    })

/**
 * This is a very simple hook that adds sugar on top of the origial useTranslation hook. It grabs the 
 * users "lanugage mode" and passes it on to i18n as a default context. All other options will be 
 * passed on to i18n. If you provide a context yourself you will override the default one.
 * 
 */
export const useTranslation = () => {
    const userLevel = useUser((user) => user.perspective) || ''
    const userLanguage = useUser((user) => user.preferred_language) || ''
    const { t, i18n } = useOriginalTranslation()

    useEffect(() => {
        if (userLanguage) {
            i18n.changeLanguage(userLanguage);
        }
    }, [i18n, userLanguage])

    const myT = (key: string, options?: TOptions) => 
        t(key, { context: userLevel, ...options })

    return { t: myT, i18n }
}