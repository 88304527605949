import React, { useEffect, useState, useRef, useCallback } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { default as CaretIcon } from '@mui/icons-material/ExpandMoreOutlined'
import Button from '@mui/material/Button'
import { default as InstanceIcon } from '@mui/icons-material/Layers'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MenuList from '@mui/material/MenuList'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import TurnRight from '@mui/icons-material/TurnRight'
import SearchIcon from '@mui/icons-material/Search'
import CircularProgress from '@mui/material/CircularProgress'
import StorageIcon from '@mui/icons-material/Storage'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import LayersIcon from '@mui/icons-material/Layers'
import { throttle } from 'lodash'

import { request } from '../../state/api'
import { switchInstance, useInstance } from '../../state/auth/currentInstance'
import { useClient, useInstancesOfClient } from '../../state/auth/currentClient'
import { useTranslation } from '../../services/i18n'
import { Instance } from '../../../../api/app/instances'

const darkTheme = createTheme({ palette: { mode: 'dark' } })

interface IResult {
  id?: string
  label: string
  sublabel?: string
  gsr_client?: string
  gsr_inst?: string
  type: 'instance' | 'account' | 'source' | 'target' | 'role' | 'information_flow' | 'page'
  target: string
}

// A little convenience helper that adds the link target to results coming back from the backend. It simply grabs the
// correct frontend path and adds the respective results id.
const addTargetToResult = (result: IResult) => ({
  ...result,
  target:
    {
      account: '/settings/accounts/',
      instance: '/settings/instances/',
      role: '/settings/roles/',
      information_flow: '/information-flows/',
      source: '/sources/',
      target: '/targets/',
      // Don't need this, really. But simplest way to silence type errors.
      page: '',
    }[result.type] + result.id,
})

// A list of pages we can mix into the backend results to add shortcuts.
// [translationKey, path]
const pages = [
  // Basic main navigation items
  ['navigation.overview', '/'],
  ['navigation.sources', '/sources'],
  ['navigation.targets', '/targets'],
  ['navigation.information-flows', '/information-flows'],
  ['assignments', '/assignments'],
  ['concepts', '/assignments/concepts'],
  ['change-rules', '/assignments/change-rules'],
  ['security-rules', '/assignments/security-rules'],
  ['privacy-rules', '/assignments/privacy-rules'],
  ['navigation.options', '/instance-options'],

  // Settings
  ['navigation.account-management', '/settings/accounts'],
  ['navigation.roles', '/settings/roles'],
  ['navigation.instances', '/settings/instances'],
  ['navigation.billing', '/settings/billing'],
  ['navigation.clients', '/settings/clients'],
  ['navigation.profile', '/settings/profile'],
  ['navigation.logout', '/settings/logout'],
  ['api-keys', '/settings/api-keys'],

  // Shortcuts to creating new elements.
  ['create-new-information-flow', '/information-flows/create'],
  ['add-new-source', '/sources/create'],
  ['add-new-target', '/targets/create'],
  ['add-new-account', '/settings/accounts/create'],
  ['add-new-client', '/settings/clients/create'],
  ['add-new-instance', '/settings/instances/create'],
  ['add-new-role', '/settings/roles/create'],
  ['create-api-key', '/settings/api-keys/create'],
]

export const InstanceSelector = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const clientId = useClient(client => client.gsr_client)
  const [q, setQ] = useState('')
  const [results, setResults] = useState<IResult[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedResultKey, setSelectedResultKey] = useState(0)
  const [hasFocus, setHasFocus] = useState(false)
  const paperRef = useRef(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const cachedT = useRef(t)

  const icons = {
    account: <PeopleIcon />,
    instance: <LayersIcon />,
    role: <VpnKeyIcon />,
    information_flow: <DashboardIcon />,
    source: <StorageIcon />,
    target: <CloudUploadIcon />,
    page: <TurnRight />,
  }

  const findPages = useCallback(
    (input: string): IResult[] =>
      pages
        // Reduce our list to those matching the search.
        .filter(page => cachedT.current(page[0]).toLowerCase().includes(input.toLowerCase()))
        // Now turn the results into valid IResults
        .map(page => ({ type: 'page', label: cachedT.current(page[0]), target: page[1] })),
    []
  )

  const exitSearch = () => {
    setQ('')
    debouncedServerSearch.cancel()
    setHasFocus(false)
    setResults([])
    setSelectedResultKey(0)
    setIsLoading(false)
    inputRef.current && inputRef.current.blur()
  }

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (
        e.target &&
        ['input', 'textarea'].includes((e.target as HTMLElement).tagName.toLowerCase())
      ) {
        // Don't screw with users trying to input something.
        return
      }

      if (e.key === '/' && inputRef.current) {
        e.preventDefault()
        inputRef.current.focus()
      }
    }

    document.addEventListener('keydown', handler)
    return () => document.removeEventListener('keydown', handler)
  }, [])

  const debouncedServerSearch = useRef(
    throttle(async (input: string) => {
      if (!input) {
        return []
      }

      setIsLoading(true)
      const { data } = await request('/search', {
        queryParameters: { q: input, gsr_client: clientId },
      })
      setIsLoading(false)

      return data
    }, 200)
  ).current

  useEffect(() => () => debouncedServerSearch.cancel(), [debouncedServerSearch])

  const performSearch = useCallback(
    async (input: string) => {
      input = input.trim()

      if (!input || input.length <= 2) {
        setResults([])
        setSelectedResultKey(0)
        return
      }

      let searchResults = findPages(input)
      // Show shortcuts right away, no need to wait for the server results.
      setResults(searchResults)

      const prevInput = input
      const serverResults = await debouncedServerSearch(input)
      if (!serverResults) {
        // No results this time, maybe the search was debounced or there's really nothing to be found.
        return
      }

      if (input !== prevInput || !input) {
        // The search term has changed in the meantime, this may indicate the search box has
        // already been closed. If we were to add our results now we'd reopen the list of results
        // and this would cause "flickering" (i.e. the list opening again after the user already
        // selected an earlier entry and navigated away).
        return
      }

      setResults(searchResults.concat((serverResults as IResult[]).map(addTargetToResult)))
    },
    [debouncedServerSearch, findPages]
  )

  // Search whenever the input changes.
  useEffect(() => {
    performSearch(q)
  }, [q, performSearch])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault()
        exitSearch()
        break
      case 'ArrowDown':
        e.preventDefault()
        setSelectedResultKey(Math.min(results.length - 1, selectedResultKey + 1))
        break
      case 'ArrowUp':
        e.preventDefault()
        setSelectedResultKey(Math.max(-1, selectedResultKey - 1))
        break
      case 'Enter':
        e.preventDefault()
        if (selectedResultKey > -1) {
          const result = results[selectedResultKey]
          exitSearch()
          navigate(result.target)
        }
        break
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <ClickAwayListener onClickAway={() => setQ('')}>
        <div>
          <Paper
            ref={paperRef}
            component="form"
            sx={{
              p: '0 5px',
              display: 'flex',
              alignItems: 'center',
              width: '500',
              flexGrow: 1,
            }}>
            <InstanceSelectorOld shrinked={hasFocus} />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <InputBase
              inputRef={inputRef}
              value={q}
              placeholder="Search"
              sx={{ ml: 1, flex: 1 }}
              onFocus={e => setHasFocus(true)}
              onBlur={e => setHasFocus(false)}
              onChange={e => setQ(e.target.value)}
              onKeyDown={e => handleKeyDown(e)}
              inputProps={{ 'aria-label': 'search google maps' }}
            />
            {!hasFocus && (
              <Button
                variant="outlined"
                size="small"
                sx={{ padding: '0 8px', margin: 1, minWidth: 'auto' }}
                disabled>
                /
              </Button>
            )}
            {!isLoading ? (
              <IconButton size="small">
                <SearchIcon sx={{ width: '.8em' }} />
              </IconButton>
            ) : (
              <CircularProgress
                color="inherit"
                size={20}
                sx={{ width: '.8em', verticalAlign: 'center', marginRight: 1 }}
              />
            )}
          </Paper>

          {paperRef.current && (
            <Popper
              open={!!results.length && !!q}
              transition
              disablePortal
              role={undefined}
              placement="bottom-start"
              anchorEl={paperRef.current}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}>
                  <Paper sx={{ width: '500', marginTop: 1, borderRadius: 1 }}>
                    <MenuList id="composition-menu" aria-labelledby="composition-button">
                      {results.map((result, key) => (
                        <MenuItem
                          selected={key === selectedResultKey}
                          key={key}
                          component={NavLink}
                          onClick={() => exitSearch()}
                          to={result.target}>
                          <ListItemIcon>{icons[result.type]}</ListItemIcon>
                          <ListItemText>{result.label}</ListItemText>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Paper>
                </Grow>
              )}
            </Popper>
          )}
        </div>
      </ClickAwayListener>
    </ThemeProvider>
  )
}

interface InstanceContainerProps {
  shrinked: boolean
}

const InstanceContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'shrinked',
})<InstanceContainerProps>(({ theme, shrinked }) => ({
  maxWidth: shrinked ? '70px' : '500px',
  opacity: shrinked ? 0.5 : 1,
  overflow: 'hidden',
  position: 'relative',
  transition: '.2s ease all',
  '> *': {
    whiteSpace: 'nowrap',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    background:
      'linear-gradient(90deg, rgba(0,0,0,0) 0%, ' + theme.palette.background.default + ' 100%)',
    width: 50,
    height: 30,
    display: shrinked ? 'block' : 'none',
  },
}))

const NameContainer = styled('span')(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginLeft: 4,
}))

export const InstanceSelectorOld = (props: { shrinked: boolean }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const currentInstance = useInstance()
  const instances = useInstancesOfClient(({ list }) =>
    list.sort((a, b) => a.name.localeCompare(b.name))
  )

  const handleSelect = (instance: Instance) => {
    setAnchorEl(null)
    switchInstance(instance)
  }

  return (
    <InstanceContainer shrinked={props.shrinked}>
      <Button
        startIcon={<InstanceIcon sx={{ width: '.8em' }} />}
        endIcon={<CaretIcon sx={{ width: '.8em' }} />}
        variant="text"
        color="inherit"
        aria-label="Settings"
        aria-controls="menu-settings"
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}>
        <NameContainer title={currentInstance.name}>{currentInstance.name}</NameContainer>
      </Button>

      <Menu
        id="menu-settings"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        {instances.map((instance, index) => (
          <MenuItem
            key={instance.gsr_inst}
            selected={instance.gsr_inst === currentInstance.gsr_inst}
            onClick={() => handleSelect(instance)}>
            {instance.name}
          </MenuItem>
        ))}
      </Menu>
    </InstanceContainer>
  )
}
