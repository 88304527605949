import React, { useState, useEffect, useCallback } from 'react'
import { Routes, Route, useParams, Link, Navigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import InputAdornment from '@mui/material/InputAdornment'
import { styled } from '@mui/system'
import { SketchPicker } from 'react-color'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import MonitorIcon from '@mui/icons-material/Monitor'
import KeyIcon from '@mui/icons-material/Key'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import UploadIcon from '@mui/icons-material/Upload'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

import { useTranslation } from '../services/i18n'
import { useSnackbar } from '../components/Snackbars'
import { useClient } from '../state/auth/currentClient'
import { Breadcrumbs } from './Breadcrumbs'
import { request } from '../state/api'
import { Client } from '../../../api/app/clients'
import { RoleAutoSuggestList } from '../components/RoleAutoSuggestList'
import { ResourceRole } from '../../../api/app/roles'
import { FormButtons } from '../components/FormButtons'
import { FormTabs } from '../components/FormTabs'
import { Form, FormContent, Label, Field, Heading } from '../components/Form'
import { FullPageDataTableContainer } from '../components/DataTable'

export const ClientsForm = () => 
    <Routes>
        <Route path="/" element={<Navigate to="general" replace/>}/>
        <Route path=":tabId" element={<Content/>}/>
    </Routes>


const scaleDownImage = async (dataUri: string, maxWidth: number, maxHeight: number, quality = 1): Promise<string> => {
    const canvas = document.createElement('canvas')
    const img = document.createElement('img')
    const context = canvas.getContext('2d')

    if (!context) return ''

    img.src = dataUri

    await new Promise(resolve => img.onload = resolve)

    context.drawImage(img, 0, 0)

    let { width, height } = img

    if (width > height) {
        if (width > maxWidth) {
            height *= maxWidth / width
            width = maxWidth
        }
    } else if (height > maxHeight) {
        width *= maxHeight / height
        height = maxHeight
    }

    canvas.width = width
    canvas.height = height

    context.drawImage(img, 0, 0, width, height)

    return canvas.toDataURL('image/jpeg', quality)
}

const Content = () => {
    const { t } = useTranslation()
    const { gsr_client: recordId, tabId: currentTab } = useParams() 
    const { showSnackbar } = useSnackbar()
    const [ , setIsLoading ] = useState(false)
    const [ isSaving, setIsSaving ] = useState(false)

    const [ licensedEdition, setLicensedEdition ] = useState(0)
    const [ name, setName ] = useState('')
    const [ primary_color, setPrimaryColor ] = useState('')
    const [ secondary_color, setSecondaryColor ] = useState('')
    const [ logo, setLogo ] = useState('')
    const [ roles, setRoles ] = useState<ResourceRole[]>([])
    const [ updatedLogo, setUpdatedLogo ] = useState('')
    const [ logoDialogOpened, setLogoDialogOpened ] = useState(false)
    const [ cropper, setCropper ] = useState<any>();

    const clientId = useClient(client => client.gsr_client)
    const refreshClient = useClient(client => client.refresh)

    const load = useCallback(async () => {
        setIsLoading(true)
        const client = (await request('/clients/' + recordId)).data as Client 
        setName(client.name || '')
        setPrimaryColor(client.primary_color || '')
        setSecondaryColor(client.secondary_color || '')
        setLogo(client.logo || '')
        setRoles(client.assignedRoles || [])
        setLicensedEdition(client.licensed_edition || 0)
        setIsLoading(false)
    }, [ recordId ])

    useEffect(() => { recordId && load() }, [ recordId, load ])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setIsSaving(true)

        const jsonBody = { name, primary_color, secondary_color, logo, roles, licensed_edition: licensedEdition, }

        try {
            if (!recordId) {
                await request('/clients', { method: 'post', jsonBody })
            } else {
                await request(`/clients/${recordId}`, { method: 'put', jsonBody })

                if (recordId === clientId) {
                    // We just updated our own client, refresh it in the store so that the changes are reflected
                    // in the rest of the UI immediately.
                    await refreshClient()
                }
            }

            showSnackbar({ message: t('save-success'), severity: 'success' })
            // navigate('../../')
        } catch (e) {
            showSnackbar({ message: t('save-error'), severity: 'error' })
        }

        setIsSaving(false)

        return false
    }

    const openAvatarDialog = () => {
        setLogoDialogOpened(true)
    }

    const handleAvatarChange = async () => {
        if (cropper && cropper.getCroppedCanvas()) {
            setLogo(await scaleDownImage(cropper.getCroppedCanvas().toDataURL(), 300, 300, .65))
        }

        setLogoDialogOpened(false)
    }

    const handleCapture = (e: React.FormEvent<HTMLInputElement>) => {
        if (!e.currentTarget || !e.currentTarget.files) {
            return
        }

        const fileReader = new FileReader();

        fileReader.readAsDataURL(e.currentTarget.files[0])
        fileReader.onload = async (e) => e?.target?.result && setUpdatedLogo(await scaleDownImage(e.target.result.toString(), 1500, 1500))
    }

    return <FullPageDataTableContainer>
        <Dialog
            fullWidth
            maxWidth="md"
            open={logoDialogOpened}
            onClose={(e) => setLogoDialogOpened(false)}
            aria-labelledby="alert-dialog-title">
            <DialogTitle id="alert-dialog-title">{t('client-change-logo-dialog-title')}</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ 
                    width: '100%',
                    padding: '1px', 
                    borderWidth: '1px', 
                    borderStyle: 'solid', 
                    borderColor: 'divider',
                    '& .cropper-view-box': {
                        outlineColor: (theme) => theme.palette.primary.main,
                    },
                }}>
                    <Cropper
                        style={{ height: 400, width: '100%' }}
                        src={updatedLogo}
                        viewMode={1}
                        center={false}
                        background={false}
                        responsive={true}
                        dragMode="move"
                        autoCropArea={1}
                        aspectRatio={800/800}
                        cropBoxMovable={false}
                        cropBoxResizable={false}
                        checkOrientation={true}
                        onInitialized={(instance) => setCropper(instance)}
                        guides={false}/>
                </Box>
                <div>
                    <IconButton onClick={() => cropper && cropper.rotate(-90)}>
                        <RotateLeftIcon/>
                    </IconButton>
                    <IconButton onClick={() => cropper && cropper.rotate(90)}>
                        <RotateRightIcon/>
                    </IconButton>
                </div>
            </DialogContent>
            <DialogActions>
                <label style={{ marginRight: 'auto' }} htmlFor="contained-button-file">
                    <input hidden onChange={handleCapture} accept="image/*" id="contained-button-file" type="file" />
                    <Button variant="outlined" startIcon={<UploadIcon/>} component="span">
                        {t('client-change-logo-dialog-upload')}
                    </Button>
                </label>
                <Button onClick={() => setLogoDialogOpened(false)}>{t('cancel')}</Button>
                <Button variant="contained" onClick={handleAvatarChange} autoFocus>
                    {t('client-change-logo-dialog-confirm')}
                </Button>
            </DialogActions>
        </Dialog>

        <Breadcrumbs>
            <Link to="../../"><Typography color="text.secondary">{t('navigation.clients')}</Typography></Link>
            <Typography color="text.primary">
                {recordId 
                    ? t('navigation.update-resource', { name })
                    : t('navigation.clients-create')
                }
            </Typography>
        </Breadcrumbs>
        <Form onSubmit={(e) => handleSubmit(e)}>
            <FormTabs tabs={[
                { id: 'general', label: t('general-information'), icon: <TextSnippetIcon/> },
                { id: 'ui', label: t('ui'), icon: <MonitorIcon/> },
                { id: 'acl', label: t('acl'), icon: <KeyIcon/> },
            ]}/>


            <FormContent hidden={currentTab !== 'general'}>
                <Label htmlFor="name">{t('name')}</Label>
                <Field>
                    <TextField
                        autoFocus
                        value={name}
                        onChange={({ target }) => setName(target.value)} 
                        margin="dense"
                        fullWidth
                        size="small"
                        variant="outlined"
                        hiddenLabel
                        type="text" />
                </Field>

                <Label htmlFor="parent">{t('parent-client')}</Label>
                <Field>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        margin="dense"
                        hiddenLabel
                        value="flow.bi"
                        disabled
                        select>
                        <MenuItem value="flow.bi">flow.bi</MenuItem>
                    </TextField>
                </Field>

                <Label htmlFor="parent">{t('licensed-edition')}</Label>
                <Field>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        margin="dense"
                        hiddenLabel
                        value={licensedEdition}
                        onChange={({ target }) => setLicensedEdition(Number(target.value))}
                        select>
                        <MenuItem value="0">{t('preview-edition')}</MenuItem>
                        <MenuItem value="1">{t('standard-edition')}</MenuItem>
                        <MenuItem value="2">{t('advanced-edition')}</MenuItem>
                        <MenuItem value="3">{t('professional-edition')}</MenuItem>
                        <MenuItem value="6">{t('enterprise-edition')}</MenuItem>
                        <MenuItem value="9">{t('developer-edition')}</MenuItem>
                    </TextField>
                </Field>
            </FormContent>

            <FormContent hidden={currentTab !== 'ui'}>
                <Heading variant="subtitle1" mt={0} mb={1}>{t('theme-and-branding')}</Heading>
                
                <Label htmlFor="test">{t('primary-color')}</Label>
                <Field>
                    <TextField
                        hiddenLabel
                        id="test"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={primary_color}
                        onChange={({ target }) => setPrimaryColor(target.value)}
                        margin="dense"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <ColorPicker
                                    onChange={({ hex }: { hex: string }) => setPrimaryColor(hex)}
                                    value={primary_color} />
                            </InputAdornment>,
                        }}
                        type="text" />
                </Field>

                <Label htmlFor="test">{t('secondary-color')}</Label>
                <Field>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={secondary_color}
                        onChange={({ target }) => setSecondaryColor(target.value)}
                        margin="dense"
                        hiddenLabel
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <ColorPicker
                                    onChange={({ hex }: { hex: string }) => setSecondaryColor(hex)}
                                    value={secondary_color} />
                            </InputAdornment>,
                        }}
                        type="text" />
                </Field>


                <Label htmlFor="test">{t('custom-logo')}</Label>
                <Field>
                    <Box sx={{ mb: 1 }}>
                        <img alt="" src={logo} style={{ maxHeight: 150, maxWidth: 150, marginBottom: '1em', }}/>
                    </Box>
                    {logo && <Button onClick={() => setLogo('')}>{t('client-reset-logo')}</Button>}
                    <Button variant="outlined" onClick={openAvatarDialog}>{t('client-change-logo')}</Button>
                </Field>

                <Heading variant="subtitle1" mt={3} mb={1}>{t('locale')}</Heading>

                <Label htmlFor="test">{t('default-interface-language')}</Label>
                <Field>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        margin="dense"
                        hiddenLabel
                        value="auto"
                        disabled
                        select>
                        <MenuItem value="auto">{t('auto-detect')}</MenuItem>
                    </TextField>
                </Field>

                <Label htmlFor="test">{t('default-interface-perspective')}</Label>
                <Field>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        margin="dense"
                        hiddenLabel
                        value="business"
                        disabled
                        select>
                        <MenuItem value="business">{t('business-user')}</MenuItem>
                        <MenuItem value="technical">{t('technical-user')}</MenuItem>
                    </TextField>
                </Field>
            </FormContent>

            <FormContent hidden={currentTab !== 'acl'}>
                <Field md={12}>
                    <RoleAutoSuggestList
                        roles={roles}
                        setRoles={setRoles}/>
                </Field>

            </FormContent>

            {(currentTab === 'acl') && <>
                <Alert severity="info">{t('permissions-hint-delay')}</Alert>
            </>}

            <FormButtons isSaving={isSaving} />
        </Form> 
    </FullPageDataTableContainer>
}

const ColorPickerBox = styled('div')((props: { value: string }) => ({
    width: '25px',
    height: '14px',
    borderRadius: '2px',
    background: props.value,
    cursor: 'pointer',
}))

const ColorPickerContainer = styled('div')({
    position: 'relative',
    border: '2px solid rgba(0,0,0,0.1)',
    padding: '1px',
})

const ColorPickerFloat = styled('div')({
    position: 'absolute',
    zIndex: 9999,
    top: '20px'
})

const ColorPickerScreen = styled('div')({
    position: 'fixed',
    top: 0, left: 0, right: 0, bottom: 0,
})

const ColorPicker = (props: any) => {
    const [ displayPicker, setDisplayPicker ] = useState(false)

    return <ColorPickerContainer>
        { displayPicker && <ColorPickerFloat>
            <ColorPickerScreen onClick={e => setDisplayPicker(false)}/>
            <SketchPicker 
                disableAlpha
                presetColors={[]}
                color={props.value} 
                onChange={props.onChange} />
        </ColorPickerFloat>}

        <ColorPickerBox
            value={props.value}
            onClick={e => setDisplayPicker(true)}/>
    </ColorPickerContainer>
}