import React from 'react'
import { default as MuiBreadcrumbs } from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

export const Breadcrumbs = (props: { children: any }) => {
    const { t } = useTranslation()

    return <MuiBreadcrumbs 
        aria-label="breadcrumb" 
        sx={{ 
            opacity: 1,
            marginBottom: 2, 
            fontSize: '.8em',
            fontWeight: 900,
            '& li p, & li a': {
                fontWeight: 500,
                fontSize: '.8rem',
                color: '#fff',
                textDecoration: 'none',
            }
        }}>
        <Typography color="text.secondary">{t('navigation.settings')}</Typography>
        {props.children}
    </MuiBreadcrumbs>
}