import React, { useState, useEffect, useCallback } from 'react'
import { Routes, Route, useParams, useNavigate, Link, Navigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import CheckIcon from '@mui/icons-material/CheckCircle'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import Typography from '@mui/material/Typography'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import KeyIcon from '@mui/icons-material/Key'

import { Breadcrumbs } from './Breadcrumbs'
import { useTranslation } from '../services/i18n'
import { useSnackbar } from '../components/Snackbars'
import { request } from '../state/api'
import { useClient } from '../state/auth/currentClient'
import { Role, Permission, PermissionApi, ResourceRole } from '../../../api/app/roles'
import { AutoSuggestList } from '../components/AutoSuggestList'
import { RoleAutoSuggestList } from '../components/RoleAutoSuggestList'
import { FormButtons } from '../components/FormButtons'
import { FormTabs } from '../components/FormTabs'
import { Form, FormContent, Label, Field } from '../components/Form'
import { FullPageDataTableContainer } from '../components/DataTable'
import { Alert } from '@mui/material'

interface FlattenedPermissions {
    [id: string]: boolean
}

export const RolesForm = () => 
    <Routes>
        <Route path="/" element={<Navigate to="general" replace/>}/>
        <Route path=":tabId" element={<Content/>}/>
    </Routes>

export const Content = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const clientId = useClient(client => client.gsr_client)

    const { role_id: recordId, tabId: currentTab } = useParams() 
    const { showSnackbar } = useSnackbar()
    const [ , setIsLoading ] = useState(false)
    const [ isSaving, setIsSaving ] = useState(false)
    
    // The role data
    const [ name, setName ] = useState<string>('')
    const [ rolePermissions, setRolePermissions ] = useState<FlattenedPermissions>({})
    const [ permissions, setPermissions ] = useState<PermissionApi[]>([])
    const [ roles, setRoles ] = useState<ResourceRole[]>([])

    const load = useCallback(async () => {
        setIsLoading(true)
        const role = (await request('/roles/' + recordId)).data as Role 
        setName(role.name || '')
        setRoles(role.assignedRoles || [])

        if (role.permissions.length) {
            // Flatten the returned response. There's different behaviour between the /roles endpoint and /permissions.
            // We should probably update /permissions to return full objects as well. But for the time being we'll do
            // this little interop.
            setRolePermissions(role.permissions.reduce((a,b) => ({ ...a, [b.id]: !!b.rolePermissions?.granted }), {}))
            setPermissions(role.permissions.map((permission: Permission) => permission.id))
        } else {
            setPermissions([])
            setRolePermissions({})
        }
        
        setIsLoading(false)
    }, [ recordId ])

    useEffect(() => { recordId && load() }, [ recordId, load ])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setIsSaving(true)

        const jsonBody = { name, roles, permissions: permissions.map(p => ({id: p, granted: !!rolePermissions[p] })) }

        try {
            if (!recordId) {
                await request(`/clients/${clientId}/roles`, { method: 'post', jsonBody })
            } else {
                await request(`/roles/${recordId}`, { method: 'put', jsonBody })
            }

            showSnackbar({ message: t('save-success'), severity: 'success' })
            navigate('../../')
        } catch (e) {
            showSnackbar({ message: t('save-error'), severity: 'error' })
        }

        setIsSaving(false)

        return false
    }

    const renderActions = (permission: string) => {
        let icon = <CheckIcon/>
        let label = t('granted')
        let color:'success'|'error' = 'success'

        if (!rolePermissions[permission]) {
            icon = <UnpublishedIcon/>
            color = 'error'
            label = t('denied')
        }

        // Toggle the value of the permission (granted/denied) on click.
        const handleClick = () => setRolePermissions({ ...rolePermissions, [permission]: !rolePermissions[permission] })

        return <Chip 
            onClick={handleClick}
            variant="outlined" 
            color={color} 
            size="small" 
            icon={icon} 
            label={label} />
    }

    return <FullPageDataTableContainer>
        <Breadcrumbs>
            <Link to="../../"><Typography color="text.secondary">{t('navigation.roles')}</Typography></Link>
            <Typography color="text.primary">
                {recordId 
                    ? t('navigation.update-resource', { name })
                    : t('navigation.roles-create')
                }
            </Typography>
        </Breadcrumbs>

        <Form onSubmit={(e) => handleSubmit(e)} sx={{display: 'flex', flexDirection: 'column', height: '100%',}}>
            <FormTabs tabs={[
                { id: 'general', label: t('general-information'), icon: <TextSnippetIcon/> },
                { id: 'permissions', label: t('permission-assignment'), icon: <TextSnippetIcon/> },
                { id: 'acl', label: t('acl'), icon: <KeyIcon/> },
            ]}/>

            <FormContent hidden={currentTab !== 'general'}>
                <Label htmlFor="name">{t('name')}</Label>
                <Field>
                    <TextField
                        autoFocus
                        id="name"
                        type="text"
                        hiddenLabel
                        value={name}
                        margin="dense"
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={e => setName(e.target.value)}/>
                </Field>
            </FormContent>
            
            <FormContent hidden={currentTab !== 'permissions'}>
                <Field md={12}>
                    <AutoSuggestList<PermissionApi>
                        items={permissions}
                        endpoint="/permissions"
                        getItemKey={permission => permission}
                        getItemLabel={permission => permission}
                        onChange={permissions => setPermissions(permissions)}
                        inputPlaceholder={t('assign-permission-to-role')}
                        removeTitle={t('remove-permission-from-role')}
                        renderActions={renderActions}/>
                </Field>
            </FormContent>

            <FormContent hidden={currentTab !== 'acl'}>
                <Field md={12}>
                    <RoleAutoSuggestList
                        roles={roles}
                        setRoles={setRoles}/>
                </Field>
            </FormContent>
            
            {(currentTab === 'permissions' || currentTab === 'acl') && <>
                <Alert severity="info">{t('permissions-hint-delay')}</Alert>
            </>}

            <FormButtons isSaving={isSaving}/>
        </Form>
    </FullPageDataTableContainer>
}