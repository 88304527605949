import React, { useCallback } from 'react'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import { default as RequestLoadIcon } from '@mui/icons-material/AddTask'
import { default as CancelLoadIcon } from '@mui/icons-material/CloseOutlined'

import { useInstance, requestLoad, cancelLoad } from '../../state/auth/currentInstance'
import { useTranslation } from '../../services/i18n'

export const LoadRequestButton = () => {
  const loadRequested = useInstance(instance => instance.load_requested)
  const canRequestLoad = useInstance(instance => instance.can_request_load)
  const hasActiveLdtsJobs = useInstance(({ has_active_ldts_jobs }) => has_active_ldts_jobs)
  const hasActiveSdtsJobs = useInstance(({ has_active_sdts_jobs }) => has_active_sdts_jobs)
  const instanceBusy = loadRequested || hasActiveLdtsJobs || hasActiveSdtsJobs
  const canCancelLoad = !hasActiveLdtsJobs && loadRequested

  const { t } = useTranslation()

  let tooltipText = t('load-request-tooltip')
  let busyLabel = ''

  if (hasActiveLdtsJobs) {
    tooltipText = t('load-request-tooltip-ldts-active')
    busyLabel = t('instance-ldts-active')
  } else if (hasActiveSdtsJobs) {
    tooltipText = t('load-request-tooltip-sdts-active')
    busyLabel = t('instance-sdts-active')
  } else if (loadRequested) {
    tooltipText = t('load-request-tooltip-load-pending')
    busyLabel = t('instance-load-pending')
  }

  const handleClick = useCallback(() => {
    if (window.confirm(t('load-request-confirmation'))) {
      requestLoad()
    }
  }, [t])

  return (
    <div>
      <Tooltip title={tooltipText} arrow>
        {/* We wrap the button in a span because tooltips are not shown for disabled elements. */}
        <span>
          {!instanceBusy && (
            <Button
              disabled={!canRequestLoad}
              color="error"
              variant="contained"
              startIcon={<RequestLoadIcon />}
              onClick={e => handleClick()}>
              {t('request-instance-load')}
            </Button>
          )}
          {instanceBusy && (
            <Box
              sx={{
                opacity: 0.5,
                display: 'flex',
                alignItems: 'center',
                columnGap: '10px',
                border: '1px solid rgba(255,255,255,.3)',
                padding: 0.5,
                paddingLeft: 1,
                paddingRight: 1,
                fontSize: '.9rem',
                transition: 'opacity .2s ease',
                '&:hover': {
                  opacity: 1,
                },
              }}>
              <CircularProgress size={15} sx={{ color: '#fff' }} />

              {busyLabel}

              {canCancelLoad && (
                <IconButton
                  size="small"
                  sx={{ color: '#fff', m: 0, p: 0 }}
                  title={t('cancel-instance-load')}
                  onClick={e => cancelLoad()}>
                  <CancelLoadIcon sx={{ width: '.9rem' }} />
                </IconButton>
              )}
            </Box>
          )}
        </span>
      </Tooltip>
    </div>
  )
}
