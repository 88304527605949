import { create } from 'zustand'
import { authStore } from '../auth'
import { request } from '../api'
import { Instance } from '../../../../api/app/instances'
import { devtools, subscribeWithSelector } from 'zustand/middleware'

interface Client {
  gsr_client?: string
  name?: string
  primary_color?: string
  secondary_color?: string
  logo?: string

  setId: (id: string) => Promise<void>
  refresh: () => Promise<void>
}

export const clientStore = create<Client>()(
  devtools(
    subscribeWithSelector((set, get) => ({
      setId: async newClientId => {
        const { data } = await request('/clients/' + newClientId)

        if (!data) {
          return
        }

        const { gsr_client, name, primary_color, secondary_color, logo } = data

        set(() => ({ gsr_client, name, primary_color, secondary_color, logo }))

        useInstancesOfClient.getState().refresh()

        // Remember the current id on reloads.
        localStorage.setItem('gsr_client', newClientId)
      },
      refresh: async () => {
        await get().setId(get().gsr_client || '')
      },
    })),
    { enabled: true, name: 'clientStore' }
  )
)
export const useClient = clientStore

export const useInstancesOfClient = create<{ list: Instance[]; refresh: () => void }>()(
  devtools(
    (set, get) => ({
      list: [],
      refresh: async () => {
        if (!clientStore.getState().gsr_client) {
          return set({ list: [] })
        }

        const { data: instances } = await request('/instances', {
          queryParameters: { gsr_client: clientStore.getState().gsr_client },
        })

        set({ list: instances })
      },
    }),
    { enabled: true, name: 'clientInstance' }
  )
)

export const init = async () => {
  // Kick off the store with "mine" to always stick to the current users own client if nothing else is specified.
  // Note how this will automatically load the correct name and real id from the API via the setter seen above.
  await clientStore.getState().setId(localStorage.getItem('gsr_client') || 'mine')

  authStore.subscribe(
    state => state.isLoggedIn,
    async (isLoggedIn?: boolean) =>
      !isLoggedIn && clientStore.setState(state => ({ setId: state.setId, refresh: state.refresh }))
    // state => state.isLoggedIn
  )

  return true
}
