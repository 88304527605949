import React, { FC, PropsWithChildren } from 'react'

import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'

import { useClient } from '../state/auth/currentClient'

export const ThemeProvider: FC<PropsWithChildren> = props => {
  const primary_color = useClient(client => client.primary_color) || '#000f8c'
  const secondary_color = useClient(client => client.secondary_color) || '#ffed00'

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: primary_color,
      },
      secondary: {
        main: secondary_color,
      },
    },
    shape: {
      borderRadius: 0,
    },
    typography: {
      fontFamily: 'Space Grotesk',
    },
  })

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>{props.children}</StyledEngineProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}
