import React, { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import { Profile } from './settings/Profile'
import { Accounts } from './settings/Accounts'
import { Instances } from './settings/Instances'
import { Roles } from './settings/Roles'
import { Clients } from './settings/Clients'
import { ApiKeys } from './settings/ApiKeys'
import { logout } from './state/auth'

export const Settings = () => 
    <Routes>
        <Route path="profile/*" element={<Profile/>}/>
        <Route path="accounts/*" element={<Accounts/>}/>
        <Route path="billing/*" element={<span></span>}/>
        <Route path="instances/*" element={<Instances/>}/>
        <Route path="roles/*" element={<Roles/>}/>
        <Route path="clients/*" element={<Clients/>}/>
        <Route path="api-keys/*" element={<ApiKeys/>}/>
        <Route path="logout" element={<Logout/>}/>
    </Routes>

const Logout = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/')
        logout()
    }, [navigate])

    return <></>
}