import React from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import { default as AddEntityIcon } from '@mui/icons-material/AddCircleOutline'
import { GridRenderCellParams } from '@mui/x-data-grid'

import { HelpBox } from '../components/HelpBox'
import { DataTable, FullPageDataTableContainer } from '../components/DataTable'
import { Breadcrumbs } from './Breadcrumbs'
import { Account } from '../../../api/app/accounts'
import { AccountsForm } from './AccountsForm'
import { useTranslation } from '../services/i18n'

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: (params: GridRenderCellParams<any, String>) => {
            return (
                <React.Fragment>
                    <Avatar 
                        src={params.row.avatar}
                        sx={{ height: 25, width: 25, marginRight: 1, fontSize: '80%' }}>
                        {params.value?.match(/(^\S\S?|\s\S)?/g)?.map(v=>v.trim())?.join("")?.match(/(^\S|\S$)?/g)?.join("")?.toLocaleUpperCase()} 
                    </Avatar>
                    {params.value}
                </React.Fragment>
            );
        },
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1,
    }
]

export const Accounts = () => 
    <Routes>
        <Route path="/" element={<AccountList/>}/>
        <Route path="create/*" element={<AccountsForm/>}/>
        <Route path=":account_id/*" element={<AccountsForm/>}/>
    </Routes>

export const AccountList = () => {   
    const { t } = useTranslation()

    return (
        <FullPageDataTableContainer>
            <Breadcrumbs>
                <Typography color="text.primary">{t('navigation.account-management')}</Typography>
            </Breadcrumbs>

            <HelpBox url="/accounts" title={t('hints-accounts-title')}>{t('hints-accounts-content')}</HelpBox>

            <Box mb={3}>
                <Button 
                    to="create"
                    color="primary" 
                    component={Link}
                    variant="contained" 
                    startIcon={<AddEntityIcon/>}>
                    {t('add-new-account')}
                </Button>
            </Box>
            
            <DataTable<Account> 
                columns={columns}
                endpoint="/accounts"
                getEditPath={(record) => `/settings/accounts/${record.id}`}/>
        </FullPageDataTableContainer>
    )
}