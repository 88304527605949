import React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Divider } from '@mui/material'
import SubjectIcon from '@mui/icons-material/Subject'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'

import { useUser } from '../state/auth/currentUser'
import { useTranslation } from '../services/i18n'

interface HelpBoxProps {
    children: React.ReactNode,
    title: React.ReactNode,
    url?: string,
}

export const HelpBox = (props: HelpBoxProps): JSX.Element|null => {
    const [ hide_hints, updateUser ] = useUser(user => [ user.hide_hints, user.update ])
    const { t } = useTranslation()

    if (hide_hints) return (null)

    const persistHideHints = async () => await updateUser({ hide_hints: true })

    return <div><Card sx={{ mb: '2em', }} variant="outlined">
        <CardContent>
            <Typography variant="h6" gutterBottom>
                {props.title}
            </Typography>
            
            <Typography variant="body2" component="p">
                {props.children}
            </Typography>
        </CardContent>
        <Divider/>
        <CardActions>
            <Button 
                target="_blank"
                href={'https://docs.flow.bi' + (props.url || '')}
                size="small" 
                variant="outlined"
                startIcon={<SubjectIcon/>}>
                {t('hints-learn-more')}
            </Button>
            <Tooltip title={t('hints-still-accessible-after-hiding')} arrow>
                <Button 
                    size="small" 
                    sx={{ textTransform: 'none', opacity: .8, ml: 1 }}
                    onClick={() => persistHideHints()}>
                    {t('hide-hints-from-now-on')}
                </Button> 
            </Tooltip>
        </CardActions>
    </Card></div>
}