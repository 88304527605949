import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import styled from '@emotion/styled'

import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import { 
    DataGrid, 
    GridColDef,
    GridCellParams,
    GridSortModel,
    GridRowIdGetter,
    GridPaginationModel,
} from '@mui/x-data-grid'

import { IPaginationParameters, request, usePaginatedRequest } from '../state/api'

export const FullPageDataTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
`

const DataTableContainer = styled.div`
    flex: 1;
    background: #fff;
    overflow: hidden;
`

interface IDataTableProps<RecordType> {
    endpoint: string,
    columns: GridColDef[],
    canDeleteRecord?: (record: RecordType) => boolean,
    onEditRecord?: (record: RecordType) => void,
    getEditPath?: (record: RecordType) => string,
    seed?: string|number,
    onRecordsLoaded?: (records: RecordType[]) => void,
    requestOptions?: IPaginationParameters,
    actions?: (record: RecordType, props: IDataTableProps<RecordType>) => React.ReactNode,
    processRowUpdate?: any,
    getRowId?: GridRowIdGetter,
}

export const DataTable = <RecordType,>(props: IDataTableProps<RecordType>) => {
    const navigate = useNavigate()
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ needsConfirmation, setNeedsConfirmation ] = useState<RecordType|undefined>(undefined)
    
    const requestOptions = {
        ...props.requestOptions,
        ...(searchParams.has('sort_by') && { sortBy: searchParams.get('sort_by') }),
        ...(searchParams.has('order') && { order: searchParams.get('order') }),
        ...(searchParams.has('page') && { page: parseInt(searchParams.get('page') || '', 10) }),
    }
    
    const { 
        records, 
        page, 
        pageSize, 
        setPage,
        setPageSize, 
        recordCount,
        refresh, 
        loading,
        setOrder,
        setSortBy,
        order,
        sortBy,
        // Seriously, fuck you TypeScript. "null is not assignable to undefined", are you trying to cause suicides wth.
        // @ts-ignore
    } = usePaginatedRequest<RecordType>(props.endpoint, requestOptions)

    const removeRecord = async (record?: RecordType) => {
        // @ts-ignore
        if (!record) return false
        const queryParams = (props.requestOptions?.queryParameters)? '?' + props.requestOptions.queryParameters : ""
        // @ts-ignore
        const path = (props.getRowId)? props.endpoint + '/' + props.getRowId(record) + queryParams :  props.endpoint + '/' + record.id + queryParams
        // @ts-ignore
        await request(path, { method: 'delete' })
        refresh()
    }

    const initialRender = useRef(false)
    useEffect(() => {
        if (!initialRender.current) {
            initialRender.current = true
        } else {
            refresh()
        }
    }, [ props.seed, refresh ])

    const { onRecordsLoaded } = props

    useEffect(() => { onRecordsLoaded && onRecordsLoaded(records) }, [ records, onRecordsLoaded ])

    const handleCellClick = (params: any) => {
        if (props.getEditPath) {
            navigate(props.getEditPath(params.row))
        }
    }

    const renderCell = useCallback((params: GridCellParams) => {
        const record = params.row as RecordType

        let editButton = <IconButton 
            onClick={() => props.onEditRecord && props.onEditRecord(record)}
            size="small">
            <EditIcon fontSize="inherit" />
        </IconButton>

        if (props.getEditPath) {
            editButton = <NavLink to={props.getEditPath(record)}>
                {editButton}
            </NavLink>
        }

        return <React.Fragment>
            {editButton}
            <IconButton 
                onClick={() => setNeedsConfirmation(record)}
                disabled={props.canDeleteRecord && !props.canDeleteRecord(record)}
                size="small">
                <DeleteIcon fontSize="inherit" />
            </IconButton>
            {props.actions && props.actions(record, props)}
        </React.Fragment>
    }, [ props ])

    // Note that we memoize the columns here. This needs to be done to keep the same reference of the list and all
    // render callbacks across rerenders. Make sure to not skip this in the future, it can cause painful little bugs,
    // for example #gs-85. Once you lose the link between the used reference of the list and our state handlers you will
    // be unable to set component state from within the callbacks.
    const columns: any = useMemo(() => {
        return [ 
            ...props.columns, 
            (!props.columns.find((column: GridColDef) => column.field === 'actions') && {
                field: 'actions',
                headerName: 'Actions',
                width: 150,
                renderCell,
            }) 
        ]
    }, [ props.columns, renderCell ])

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        if (!sortModel.length) 
            return

        const field = sortModel[0].field
        const sort = sortModel[0].sort || 'desc'

        setSortBy(field)
        setOrder(sort)

        searchParams.set('sort_by', field)
        searchParams.set('order', sort)

        setSearchParams(searchParams)
    }, [ setSortBy, setOrder, searchParams, setSearchParams ]);

    const handlePageChange = useCallback((newPage: number) => {
        setPage(newPage)

        searchParams.set('page', String(newPage))
        setSearchParams(searchParams)
    }, [ setSearchParams, setPage, searchParams ])

    const handlePageSizeChange = useCallback((newPageSize: number) => {
        setPageSize(newPageSize)
    }, [ setPageSize ])

    const setPaginationModel = (model: GridPaginationModel) => {
        if (model.page !== page ) handlePageChange(model.page)
        if (model.pageSize !== pageSize) handlePageSizeChange(model.pageSize)
    }

    return (
        <DataTableContainer>
            <DataGrid
                getRowId={props.getRowId}
                processRowUpdate={props.processRowUpdate}
                disableRowSelectionOnClick
                pagination
                loading={loading} 
                rows={records as {}[]} 
                columns={columns} 
                paginationModel={{ page, pageSize }}
                rowCount={recordCount}
                pageSizeOptions={[ 20, 50, 100, 350, ]}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                sortModel={[{ field: sortBy || '', sort: order, }]}
                components={{  }} 
                onCellDoubleClick={handleCellClick} />

            <DeleteConfirmationinDialog
                open={!!needsConfirmation}
                onConfirm={() => {
                    setNeedsConfirmation(undefined) 
                    removeRecord(needsConfirmation)
                }}
                onCancel={() => setNeedsConfirmation(undefined)}/>
        </DataTableContainer>
    );
}

interface IDeleteConfirmationDialogProps {
    onConfirm: () => void, 
    onCancel: () => void,
    open?: boolean,
}

const DeleteConfirmationinDialog = (props: IDeleteConfirmationDialogProps) => {

    return <Dialog
        open={!!props.open}
        onClose={props.onCancel}>
        <DialogTitle>Remove this record?</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Do you want to remove the selected record?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancel} color="primary">
                Cancel
            </Button>
            <Button onClick={props.onConfirm} color="primary" variant="contained" autoFocus>
                Remove
            </Button>
        </DialogActions>
    </Dialog>
}