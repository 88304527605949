import React from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import { default as AddEntityIcon } from '@mui/icons-material/AddCircleOutline'

import { HelpBox } from './components/HelpBox'
import { DataTable, FullPageDataTableContainer } from './components/DataTable'
import { ConnectedSystemsForm } from './ConnectedSystemsForm'
import { Source } from '../../api/app/sources'
import { useInstance } from './state/auth/currentInstance'
import { useTranslation } from './services/i18n'

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
    },
    {
        field: 'driver',
        headerName: 'Driver',
        flex: 1,
    }
] 

export const Sources = () => 
    <Routes>
        <Route path="/" element={<SourcesList/>}/>
        <Route path="create/*" element={<ConnectedSystemsForm />}/>
        <Route path=":connectedSystemId/*" element={<ConnectedSystemsForm />}/>
    </Routes>

export const SourcesList = () => {
    const { t } = useTranslation()
    const instanceId = useInstance(instance => instance.gsr_inst)

    return (
        <FullPageDataTableContainer>
            <HelpBox title={<Skeleton animation={false} width={500} />}>
                <Skeleton animation={false} />
                <Skeleton animation={false} />
                <Skeleton animation={false} width={600} />
            </HelpBox>

            <Box mb={3}>
                <Button 
                    to="create"
                    color="primary" 
                    component={Link}
                    variant="contained" 
                    startIcon={<AddEntityIcon/>}>
                    {t('add-new-source')}
                </Button>
            </Box>
            
            <DataTable<Source>
                columns={columns}
                endpoint={`/sources`}
                getRowId={record => record.gsr_src}
                getEditPath={(record) => record.gsr_src}
                requestOptions={{ queryParameters: { gsr_inst: instanceId } }}/>
        </FullPageDataTableContainer>
    )
}