import React from 'react'
import { default as SaveIcon } from '@mui/icons-material/SaveOutlined'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { Link } from 'react-router-dom'
import { useTranslation } from '../services/i18n'

export const FormButtons = ({canSave = true, ...props}: { 
    onCancel?: () => void, 
    onSubmit?: () => void,
    canSave?: boolean,
    isSaving?: boolean,
}) => {
    const { t } = useTranslation()

    return <Box sx={{ paddingTop: 2, borderTop: 1, borderColor: 'divider' }}>
        <LoadingButton
            aria-label={t('save-changes')}
            disabled={!canSave}
            loading={props.isSaving}
            loadingPosition="start"
            onClick={props.onSubmit}
            startIcon={<SaveIcon/>}
            variant="contained"
            color="primary"
            type="submit">
            {t('save-changes')}
        </LoadingButton>
        <Button
            aria-label={t('discard-changes')}
            sx={{ml: 2}}
            to="../../"
            component={Link}
            onClick={props.onCancel}> 
            {t('discard-changes')}
        </Button>
    </Box>
}