import React, { useState } from 'react'
import { Routes, Route, useParams, Navigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import UploadIcon from '@mui/icons-material/Upload'
import Avatar from '@mui/material/Avatar'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import MonitorIcon from '@mui/icons-material/Monitor'
import KeyIcon from '@mui/icons-material/Key'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import { Box } from '@mui/material'

import { FormButtons } from '../components/FormButtons'
import { FormTabs } from '../components/FormTabs'
import { Form, FormContent, Label, Field, Heading } from '../components/Form'
import { useTranslation } from '../services/i18n'
import { useUser } from '../state/auth/currentUser'
import { Breadcrumbs } from './Breadcrumbs'
import { useSnackbar } from '../components/Snackbars'
import { FullPageDataTableContainer } from '../components/DataTable'

const scaleDownImage = async (dataUri: string, maxWidth: number, maxHeight: number, quality = 1): Promise<string> => {
    const canvas = document.createElement('canvas')
    const img = document.createElement('img')
    const context = canvas.getContext('2d')

    if (!context) return ''

    img.src = dataUri

    await new Promise(resolve => img.onload = resolve)

    context.drawImage(img, 0, 0)

    let { width, height } = img

    if (width > height) {
        if (width > maxWidth) {
            height *= maxWidth / width
            width = maxWidth
        }
    } else if (height > maxHeight) {
        width *= maxHeight / height
        height = maxHeight
    }

    canvas.width = width
    canvas.height = height

    context.drawImage(img, 0, 0, width, height)

    return canvas.toDataURL('image/jpeg', quality)
}

export const Profile = () => 
    <Routes>
        <Route path="/" element={<Navigate to="general" replace/>}/>
        <Route path=":tabId" element={<ProfileForm/>}/>
    </Routes>

export const ProfileForm = () => {
    const { t } = useTranslation()
    const { tabId: currentTab } = useParams() 
    const { showSnackbar } = useSnackbar()
    
    const user = useUser((user) => user)
    const [ data, setData ] = useState(user)
    const [ isSaving, setIsSaving ] = useState(false)
    const [ uploadedAvatar, setUploadedAvatar ] = useState('')
    const [ avatarDialogOpened, setAvatarDialogOpened ] = useState(false)
    const [ cropper, setCropper ] = useState<any>();

    const [ newPassword, setNewPassword ] = useState('')
    const [ confirmNewPassword, setConfirmNewPassword ] = useState('')
    const [ oldPassword, setOldPassword ] = useState('')
    const [ editPassword, setEditPassword ] = useState<Boolean>(false)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSaving(true)

        try {
            if (editPassword) {
                if (validatePassword(newPassword, confirmNewPassword)) {
                    await user.changePassword(oldPassword, newPassword)
                }
                else {
                    showSnackbar({ message: t('save-password-error'), severity: 'error' })
                    setIsSaving(false)
                    return
                }
                    
            }
            await user.update(data)
            showSnackbar({ message: t('save-success'), severity: 'success' })
        } catch (e) {
            showSnackbar({ message: t('save-error'), severity: 'error' })
        }

        setIsSaving(false)
    }

    const openAvatarDialog = () => {
        setAvatarDialogOpened(true)
    }

    const handleAvatarChange = async () => {
        if (cropper && cropper.getCroppedCanvas()) {
            setData({ ...data, avatar: await scaleDownImage(cropper.getCroppedCanvas().toDataURL(), 300, 300, .65)})
        }

        setAvatarDialogOpened(false)
        // Intentionally not clearing this here so the user can come back to fix his cropping before saving.
        // setUploadedAvatar('')
    }

    const handleCapture = (e: React.FormEvent<HTMLInputElement>) => {
        if (!e.currentTarget || !e.currentTarget.files) {
            return
        }

        const fileReader = new FileReader();

        fileReader.readAsDataURL(e.currentTarget.files[0])
        fileReader.onload = async (e) => e?.target?.result && setUploadedAvatar(await scaleDownImage(e.target.result.toString(), 1500, 1500))
    }

    const [ confirmPasswordError, setConfirmPasswordError ] = useState('')
    const [ shortPasswortError, setShortPasswordError ] = useState('')

    const validatePassword = (pass = '', valpass = '') => {
        // just for example, should be synced with api
        pass.length < 3 ? setShortPasswordError (t('password-short')) : setShortPasswordError('') 
        valpass !== pass ? setConfirmPasswordError (t('password-missmatch')) : setConfirmPasswordError('')            
        return pass.length >= 3 && valpass === pass
    }

    return <FullPageDataTableContainer>
        <Dialog
            fullWidth
            maxWidth="md"
            open={avatarDialogOpened}
            onClose={(e) => setAvatarDialogOpened(false)}
            aria-labelledby="alert-dialog-title">
            <DialogTitle id="alert-dialog-title">{t('profile-change-avatar-dialog-title')}</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ 
                    width: '100%',
                    padding: '1px', 
                    borderWidth: '1px', 
                    borderStyle: 'solid', 
                    borderColor: 'divider',
                    '& .cropper-view-box': {
                        outlineColor: (theme) => theme.palette.primary.main,
                    },
                    '& .cropper-view-box, & .cropper-face': {
                        borderRadius: '100%',
                    }
                }}>
                    <Cropper
                        style={{ height: 400, width: '100%' }}
                        src={uploadedAvatar}
                        viewMode={1}
                        center={false}
                        background={false}
                        responsive={true}
                        dragMode="move"
                        autoCropArea={1}
                        aspectRatio={800/800}
                        cropBoxMovable={false}
                        cropBoxResizable={false}
                        checkOrientation={true}
                        onInitialized={(instance) => setCropper(instance)}
                        guides={false}/>
                </Box>
                <div>
                    <IconButton onClick={() => cropper && cropper.rotate(-90)}>
                        <RotateLeftIcon/>
                    </IconButton>
                    <IconButton onClick={() => cropper && cropper.rotate(90)}>
                        <RotateRightIcon/>
                    </IconButton>
                </div>
            </DialogContent>
            <DialogActions>
                <label style={{ marginRight: 'auto' }} htmlFor="contained-button-file">
                    <input hidden onChange={handleCapture} accept="image/*" id="contained-button-file" type="file" />
                    <Button variant="outlined" startIcon={<UploadIcon/>} component="span">
                        {t('profile-change-avatar-dialog-upload')}
                    </Button>
                </label>
                <Button onClick={() => setAvatarDialogOpened(false)}>{t('cancel')}</Button>
                <Button variant="contained" onClick={handleAvatarChange} autoFocus>
                    {t('profile-change-avatar-dialog-confirm')}
                </Button>
            </DialogActions>
        </Dialog>

        <Breadcrumbs>
            <Typography color="text.secondary">{user.name}</Typography>
            <Typography color="text.primary">{t('navigation.profile')}</Typography>
        </Breadcrumbs>
        <Form onSubmit={(e) => handleSubmit(e)}>
            <FormTabs tabs={[
                { id: 'general', label: t('general-information'), icon: <TextSnippetIcon/> },
                { id: 'ui', label: t('ui'), icon: <MonitorIcon/> },
                { id: 'security', label: t('security'), icon: <KeyIcon/> },
            ]}/>

            <FormContent hidden={currentTab !== 'general'}>
                <Label htmlFor="name">{t('name')}</Label>
                <Field>
                    <TextField
                        autoFocus
                        id="name"
                        type="text"
                        hiddenLabel
                        value={data.name}
                        margin="dense"
                        variant="standard"
                        onChange={e => setData({ ...data, name: e.target.value })}/>
                </Field>

                <Label htmlFor="email">{t('email-address')}</Label>
                <Field>
                    <TextField
                        id="email"
                        type="text"
                        hiddenLabel
                        value={data.email}
                        margin="dense"
                        variant="standard"
                        onChange={e => setData({ ...data, email: e.target.value })}/>
                </Field>

                <Label htmlFor="avatar">{t('avatar')}</Label>
                <Field>
                    <div>
                        <Avatar 
                            src={data.avatar}
                            sx={{ height: 150, width: 150, marginBottom: 1, }}>
                            {data.name?.match(/(^\S\S?|\s\S)?/g)?.map(v=>v.trim())?.join("")?.match(/(^\S|\S$)?/g)?.join("")?.toLocaleUpperCase()} 
                        </Avatar>
                    </div>
                    {data.avatar && <Button onClick={() => setData({ ...data, avatar: '' })}>{t('profile-reset-avatar')}</Button>}
                    <Button variant="outlined" onClick={openAvatarDialog}>{t('profile-change-avatar')}</Button>
                </Field>
            </FormContent>

            <FormContent hidden={currentTab !== 'security'}>
                <Heading variant="subtitle2" mt={1} mb={2}>{t('authentication')}</Heading>

                {!editPassword && 
                    <React.Fragment>
                        <Label/>
                        <Field>
                            <Button 
                                size="small"
                                variant="text" 
                                onClick={e => setEditPassword(true)}>
                                {t('change-password')}
                            </Button>
                        </Field>
                    </React.Fragment>}

                {editPassword && 
                    <React.Fragment>
                        <Label htmlFor="old-password">{t('old-password')}</Label>
                        <Field>
                            <TextField
                                hiddenLabel
                                id="old-password"
                                margin="dense"
                                type="password"
                                value={oldPassword}
                                variant="standard"
                                onChange={e => setOldPassword(e.target.value)} />
                        </Field>

                        <Label htmlFor="new-password">{t('new-password')}</Label>
                        <Field>
                            <TextField
                                hiddenLabel
                                id="new-password"
                                error={shortPasswortError.length > 0}
                                helperText={shortPasswortError}
                                margin="dense"
                                type="password"
                                value={newPassword}
                                variant="standard"
                                onChange={e => {setNewPassword(e.target.value); validatePassword(e.target.value, confirmNewPassword)} } />
                        </Field>

                        <Label htmlFor="new-password-confirm">{t('new-password-confirm')}</Label>
                        <Field>
                            <TextField
                                hiddenLabel
                                id="new-password-confirm"
                                name="new-password-confirm"
                                error={confirmPasswordError.length > 0}
                                helperText={confirmPasswordError}
                                margin="dense"
                                type="password"
                                value={confirmNewPassword}
                                variant="standard"
                                onChange={e => {setConfirmNewPassword(e.target.value); validatePassword(newPassword, e.target.value)} } />
                        </Field>
                    </React.Fragment>}
            </FormContent>

            <FormContent hidden={currentTab !== 'ui'}>
                <Label htmlFor="language">{t('preferred-language')}</Label>
                <Field>    
                    <TextField
                        select
                        hiddenLabel
                        id="language"
                        margin="dense"
                        variant="standard"
                        value={data.preferred_language}
                        onChange={({ target }) => setData({ ...data, preferred_language: target.value })}>
                        <MenuItem value="">{t('detect-automatically')}</MenuItem>
                        <MenuItem value="en-US">{t('en-US')}</MenuItem>
                    </TextField>
                </Field>
                <Label htmlFor="perspective">{t('interface-perspective')}</Label>
                <Field>   
                    <TextField
                        select
                        hiddenLabel
                        margin="dense"
                        id="perspective"
                        variant="standard"
                        value={data.perspective}
                        onChange={({ target }) => setData({ ...data, perspective: target.value })}>
                        <MenuItem value="business">{t('business-user')}</MenuItem>
                        <MenuItem value="technical">{t('technical-user')}</MenuItem>
                    </TextField>
                </Field>
                <Label htmlFor="hints">{t('interface-hide-hints')}</Label>
                <Field>   
                    <Checkbox 
                        id="hints" 
                        checked={data.hide_hints}
                        onChange={({ target }) => setData({ ...data, hide_hints: target.checked })}/>
                </Field>
                
                <Label htmlFor="vr">{t('interface-use-vr-data-model')}</Label>
                <Field>   
                    <Checkbox 
                        id="vr" 
                        checked={data.use_vr_data_model}
                        onChange={({ target }) => setData({ ...data, use_vr_data_model: target.checked })}/>
                </Field> 
            </FormContent>

            <FormButtons isSaving={isSaving} />
        </Form>
    </FullPageDataTableContainer>
}