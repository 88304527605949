import React, { useState, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import CircularProgress from '@mui/material/CircularProgress'
import { default as DoneIcon } from '@mui/icons-material/CheckCircleOutlined'
import { default as ScheduledIcon } from '@mui/icons-material/ScheduleOutlined'

import { useTranslation } from './services/i18n'
import { getInstanceJobs, getSourceJobs } from './state/auth/currentInstance'
import { Typography } from '@mui/material'
import { FullPageDataTableContainer } from './components/DataTable'

interface Job {
    gsr_seq: string, 
    gsr_sdts: string, 
    priority: boolean, 
    inst_scheduled: string, 
    inst_started: string, 
    inst_finished: string, 
    cc_scheduled: string, 
    cc_started: string, 
    cc_finished: string, 
    fk1_scheduled: string, 
    fk1_started: string, 
    fk1_finished: string, 
    fk2_scheduled: string, 
    fk2_started: string, 
    fk2_finished: string, 
    ms_scheduled: string, 
    ms_started: string, 
    ms_finished: string, 
    re1_scheduled: string, 
    re1_started: string, 
    re1_finished: string, 
    re2_scheduled: string, 
    re2_started: string, 
    re2_finished: string, 
    au_scheduled: string, 
    au_started: string, 
    au_finished: string, 
    dp_scheduled: string, 
    dp_started: string, 
    dp_finished: string,
    jobs_overall: number,
    jobs_running: number,
    jobs_finished: number,
    jobs_aborted: number,
    fk1_jobs_finished: number,
    fk2_jobs_finished: number,
    re1_jobs_finished: number,
    re2_jobs_finished: number,
}

interface SourceJob {
    name: string,
    gsr_seq: string,
    gsr_client: string,
    gsr_inst: string,
    gsr_source: string,
    gsr_ldts: string,
    priority: boolean,
    src_scheduled: string,
    src_started: string,
    src_finished: string,
    ml_scheduled: string,
    ml_started: string,
    ml_finished: string,
    rl_scheduled: string,
    rl_started: string,
    rl_finished: string,
    continue: boolean,
    dl_scheduled: string,
    dl_started: string,
    dl_finished: string,
    bk_scheduled: string,
    bk_started: string,
    bk_finished: string,
    is_latest: boolean,
}

const DoneCell = () => <DoneIcon sx={{ width: '1rem', color: '#5ab642' }}/>
const ProgressCell = () => <CircularProgress size={15} sx={{ }} />
const ScheduledCell = () => <ScheduledIcon sx={{ width: '1rem', }}/>

export const InstanceStatus = () => {    
    return <FullPageDataTableContainer>
        <div style={{ flex: 1, background: '#fff', overflow: 'hidden', }}>
            <InstanceBaseStatus/>
            <SourceJobStatus/>
        </div>
    </FullPageDataTableContainer>
}

const InstanceBaseStatus = () => {
    const { t } = useTranslation()
    const [ lastJob, setLastJob ] = useState<Job>()
    const [ lastUpdate, setLastUpdate ] = useState<Date>()

    const fetchJobs = useCallback(async () => {
        const jobs = await getInstanceJobs()
        setLastJob(jobs[0])
        setLastUpdate(new Date())
    }, [])

    useEffect(() => {
        fetchJobs()
        const timer = setInterval(fetchJobs, 10000)
        return () => clearInterval(timer)
    }, [fetchJobs])

    const activeStep = getLastActiveJob(lastJob)

    return <Box sx={{mt: 4, mb: 8, }}>
        <Box sx={{ pl: 4, mb: 8, }}>
            <Typography variant="h6">{t('instance-status')}</Typography>
            <div style={{ fontSize: '.9em', opacity: .5 }}>{t('last-update')}: {lastUpdate?.toLocaleTimeString()}</div>
        </Box>
        <Stepper activeStep={activeStep} alternativeLabel>
            <Step completed={isCompleted('cc', lastJob)}>
                <StepLabel>
                    <div>{t('instance-jobs-cc')}</div>
                    <div>
                        {(activeStep !== 0) && getPercentageOfComponent('cc', lastJob)+'%'}
                        {(activeStep === 0) && <CircularProgress size={10} sx={{ color: '#000', }} />}
                    </div>
                </StepLabel>
            </Step>
            <Step completed={isCompleted('fk1', lastJob) && isCompleted('fk2', lastJob)}>
            <StepLabel>
                    <div>{t('instance-jobs-fk')}</div>
                    <div>
                        {(activeStep === 1) && <CircularProgress size={10} sx={{ color: '#000', }} />}&nbsp;
                        {Math.round((getPercentageOfComponent('fk1', lastJob) + getPercentageOfComponent('fk2', lastJob)) / 2)}%
                    </div>
                    {(activeStep === 1) && 
                        <div style={{ fontSize: '.8em' }}>{getPercentageOfComponent('fk1', lastJob)}% · {getPercentageOfComponent('fk2', lastJob)}%</div>
                    }
                </StepLabel>
            </Step>
            <Step completed={isCompleted('ms', lastJob)}>
                <StepLabel>
                    <div>{t('instance-jobs-ms')}</div>
                    <div>
                        {(activeStep !== 2) && getPercentageOfComponent('ms', lastJob)+'%'}
                        {(activeStep === 2) && <CircularProgress size={10} sx={{ color: '#000', }} />}
                    </div>
                </StepLabel>
            </Step>
            <Step completed={isCompleted('re1', lastJob) && isCompleted('re2', lastJob)}>
                <StepLabel>
                    <div>{t('instance-jobs-re')}</div>
                    <div>
                        {(activeStep === 3) && <CircularProgress size={10} sx={{ color: '#000', }} />}&nbsp;
                        {Math.round((getPercentageOfComponent('re1', lastJob) + getPercentageOfComponent('re2', lastJob)) / 2)}%
                    </div>
                    {(activeStep === 3) && 
                        <div style={{ fontSize: '.8em' }}>{getPercentageOfComponent('re1', lastJob)}% · {getPercentageOfComponent('re2', lastJob)}%</div>
                    }
                </StepLabel>
            </Step>
            <Step completed={isCompleted('au', lastJob)}>
                <StepLabel>
                    <div>{t('instance-jobs-au')}</div>
                    <div>
                        {(activeStep !== 4) && getPercentageOfComponent('au', lastJob)+'%'}
                        {(activeStep === 4) && <CircularProgress size={10} sx={{ color: '#000', }} />}
                    </div>
                </StepLabel>
            </Step>
            <Step completed={isCompleted('dp', lastJob)}>
                <StepLabel>
                    <div>{t('instance-jobs-dp')}</div>
                    <div>
                        {(activeStep !== 5) && getPercentageOfComponent('dp', lastJob)+'%'}
                        {(activeStep === 5) && <CircularProgress size={10} sx={{ color: '#000', }} />}
                    </div>
                </StepLabel>
            </Step>
        </Stepper>
    </Box>
}

const SourceJobStatus = () => {
    const { t } = useTranslation()
    const [ jobs, setJobs ] = useState<SourceJob[]>([])
    const [ lastUpdate, setLastUpdate ] = useState<Date>()

    const fetchJobs = useCallback(async () => {
        const jobs = await getSourceJobs()
        setJobs(jobs)
        setLastUpdate(new Date())
    }, [])

    useEffect(() => {
        fetchJobs()
        const timer = setInterval(fetchJobs, 10000)
        return () => clearInterval(timer)
    }, [fetchJobs])

    return <Box sx={{
        borderTopColor: 'divider', 
        borderTopWidth: '1px', 
        borderTopStyle: 'solid', 
        pt: 8,
    }}>
        <Box sx={{ pl: 4, mb: 4, }}>
            <Typography variant="h6">{t('sources-status')}</Typography>
            <div style={{ fontSize: '.9em', opacity: .5 }}>{t('last-update')}: {lastUpdate?.toLocaleTimeString()}</div>
        </Box>
        <Table size="small" sx={{}}>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center">{t('instance-jobs-ml')}</TableCell>
                    <TableCell align="center">{t('instance-jobs-rl')}</TableCell>
                    <TableCell align="center">{t('instance-jobs-dl')}</TableCell>
                    <TableCell align="center">{t('instance-jobs-bk')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(jobs || []).map(job => 
                    <TableRow
                        key={job.gsr_seq}
                        sx={{ 
                            '&:last-child td': { border: 0 },
                            ...(job.src_finished && { background: '#d7f8cf' }),
                            ...((!job.src_finished && job.src_started) && { background: '#fefbe7' }),
                            ...(!job.src_started && { background: '#f7f7f7' })
                        }}>
                        <TableCell>{job.name} <span style={{ opacity: .5, }}>{job.gsr_seq}</span></TableCell>
                        {(!job.src_started) && 
                            <TableCell colSpan={6} align="center">{t('instance-jobs-scheduled')}</TableCell>
                        }
                        {(job.src_started ) && <React.Fragment>
                            <TableCell align="center">{renderCell('ml', job)}</TableCell>
                            <TableCell align="center">{renderCell('rl', job)}</TableCell>
                            <TableCell align="center">{renderCell('dl', job)}</TableCell>
                            <TableCell align="center">{renderCell('bk', job)}</TableCell>
                        </React.Fragment>}
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </Box>
}

const getLastActiveJob = (job?: Job) => {
    if (!job) return -1

    if (isStarted('cc', job) && !isCompleted('cc', job)) return 0
    if (isStarted('fk1', job) && !isCompleted('fk1', job)) return 1
    if (isStarted('fk2', job) && !isCompleted('fk2', job)) return 1
    if (isStarted('ms', job) && !isCompleted('ms', job)) return 2
    if (isStarted('re1', job) && !isCompleted('re1', job)) return 3
    if (isStarted('re2', job) && !isCompleted('re2', job)) return 3
    if (isStarted('au', job) && !isCompleted('au', job)) return 4
    if (isStarted('dp', job) && !isCompleted('dp', job)) return 5

    return -1
}

const getPercentageOfComponent = (prefix: string, job?: Job) => {
    if (!job) return 0

    if (isCompleted(prefix, job)) return 100

    if (isStarted(prefix, job)) {
        if (prefix === 'cc' || prefix === 'ms' || prefix === 'au') {
            // We have no detailed information about these components.
            return 0
        }
        const key = prefix + '_jobs_finished' as keyof Job
        return Math.round((Number(job[key])/(Number(job[key]) + Number(job.jobs_running))) * 100)
    }

    return 0
}

const isCompleted = (prefix: string, job?: Job) => {
    if (!job)
        return false

    // @ts-ignore
    const scheduled = job[prefix + '_scheduled']
    // @ts-ignore
    const started = job[prefix + '_started']
    // @ts-ignore
    const finished = job[prefix + '_finished']

    if (finished) {
        return true
    }

    if (started) {
        return false
    }

    if (scheduled) {
        return false
    }

    return false
}

const isStarted = (prefix: string, job?: Job) => {
    if (!job)
        return false

    // @ts-ignore
    const scheduled = job[prefix + '_scheduled']
    // @ts-ignore
    const started = job[prefix + '_started']
    // @ts-ignore
    const finished = job[prefix + '_finished']

    if (finished) {
        return true
    }

    if (started) {
        return true
    }

    if (scheduled) {
        return false
    }

    return false
}

const renderCell = (prefix: string, job: SourceJob) => {
    // @ts-ignore
    const scheduled = job[prefix + '_scheduled']
    // @ts-ignore
    const started = job[prefix + '_started']
    // @ts-ignore
    const finished = job[prefix + '_finished']

    if (finished) {
        return <DoneCell/>
    }

    if (started) {
        return <ProgressCell/>
    }

    if (scheduled) {
        return <ScheduledCell/>
    }

    return '..'
}