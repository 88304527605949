import React, { useContext, createContext, useState, useCallback } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert, { AlertProps } from '@mui/material/Alert'

interface ISnackbarContext { 
    showSnackbar: (snackbar: TSnackbar) => void, 
    setSnackbars: (snackbars: TSnackbar[]) => void 
}

type TSnackbar = {
    id?: string,
    message: string,
    severity?: AlertProps['severity'],
    open?: boolean,
}

export const SnackbarContext = createContext<ISnackbarContext>({
    showSnackbar: (snackbar: TSnackbar) => {},
    setSnackbars: (snackbars: TSnackbar[]) => {},
}) 

/**
 * 'Tis but a little helper to easily display snackbar alerts across the app by using hooks.
 * 
 * const { showSnackbar } = useSnackbar()
 * showSnackbar({ message: 'That was a success', severity: 'success' }) 
 * 
 */
export function SnackBarProvider(props: { children: any }) {
    const [snackbars, setSnackbars] = useState<TSnackbar[]>([])
    const showSnackbar = useCallback((snackbar: TSnackbar) => setSnackbars(list => [ ...list, { id: String(Date.now()), ...snackbar } ]), [])
    const handleClosing = (snackbar: TSnackbar) => setSnackbars(snackbars.map(s => s.id === snackbar.id ? { ...snackbar, open: false } : s))

    return (
        <SnackbarContext.Provider value={{ setSnackbars, showSnackbar }}>
            {props.children}
            {snackbars.map(snackbar => (
                <Snackbar 
                    key={snackbar.id}
                    autoHideDuration={5000}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
                    onClose={() => handleClosing(snackbar)} 
                    open={typeof snackbar.open === 'undefined' ? true : snackbar.open}>
                    <Alert severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            ))}
        </SnackbarContext.Provider>
    )
}

export const useSnackbar = () => useContext(SnackbarContext)