import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

// Declare the global configuration variable
declare var flowBiConfig: ConfigStore

// Define the ConfigStore type
type ConfigStore = {
  revision?: string
  version?: string
  buildTime?: string
  apiUrl?: string
  reportsUrl?: string
  environment?: string
}

// Create the Zustand store
export const configStore = create<ConfigStore>()(
  devtools((set, get) => ({}), { enabled: true, name: 'configStore' })
)
export const useConfig = configStore

// Default configuration values
const DEFAULTS = {
  REVISION: '00000000',
  BUILD_TIME: new Date().toLocaleString(),
  API_URL: 'http://localhost',
  REPORTS_URL: 'http://localhost',
  ENVIRONMENT: 'production',
  VERSION_PREFIX: 'v.',
}

// Function to set default values if placeholders are found
// code reference - look for flowBiConfig in index.html
const setDefaultValues = (config: ConfigStore) => {
  if (config.revision === '__REVISION__' || config.revision === undefined) {
    config.revision = DEFAULTS.REVISION
  }

  if (config.buildTime === '__BUILD_TIME__' || config.buildTime === undefined) {
    config.buildTime = DEFAULTS.BUILD_TIME
  } else {
    config.buildTime = new Date(Number(config.buildTime) * 1000).toLocaleString()
  }

  if (config.apiUrl === '__API_URL__' || config.apiUrl === undefined) {
    config.apiUrl = DEFAULTS.API_URL
  }

  if (config.reportsUrl === '__REPORTS_URL__' || config.reportsUrl === undefined) {
    config.reportsUrl = DEFAULTS.REPORTS_URL
  }

  // Ensure environment is either 'preview' or 'production'
  if (config.environment !== 'preview' && config.environment !== 'production') {
    config.environment = DEFAULTS.ENVIRONMENT
  }

  config.version = (DEFAULTS.VERSION_PREFIX + config.revision).substring(0, 10)
}

// Inject the global configuration
const injectedConfig = flowBiConfig

// Apply the configuration if available
if (injectedConfig) {
  setDefaultValues(injectedConfig)
  configStore.setState(injectedConfig)
} else {
  console.error('Injected configuration is missing.')
}
