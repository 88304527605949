import React from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import { default as AddEntityIcon } from '@mui/icons-material/AddCircleOutline'

import { HelpBox } from '../components/HelpBox'
import { DataTable, FullPageDataTableContainer } from '../components/DataTable'
import { Breadcrumbs } from './Breadcrumbs'
import { InstancesForm } from './InstancesForm'
import { Instance } from '../../../api/app/instances'
import { useTranslation } from '../services/i18n'

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
    },
]

export const Instances = () => 
    <Routes>
        <Route path="/" element={<InstancesList/>}/>
        <Route path="create/*" element={<InstancesForm/>}/>
        <Route path=":gsr_inst/*" element={<InstancesForm/>}/>
    </Routes>

export const InstancesList = () => {    
    const { t } = useTranslation()

    return (
        <FullPageDataTableContainer>
            <Breadcrumbs>
                <Typography color="text.primary">{t('navigation.instances')}</Typography>
            </Breadcrumbs>

            <HelpBox title={<Skeleton animation={false} width={500} />}>
                <Skeleton animation={false} />
                <Skeleton animation={false} />
                <Skeleton animation={false} width={600} />
            </HelpBox>

            <Box mb={3}>
                <Button 
                    to="create"
                    color="primary" 
                    component={Link}
                    variant="contained" 
                    startIcon={<AddEntityIcon/>}>
                    {t('add-new-instance')}
                </Button>
            </Box>
            
            <DataTable<Instance> 
                getRowId={row => row.gsr_inst}
                columns={columns}
                endpoint="/instances"
                getEditPath={(record) => `/settings/instances/${record.gsr_inst}`} />
        </FullPageDataTableContainer>
    )
}