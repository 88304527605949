import React from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import { default as AddEntityIcon } from '@mui/icons-material/AddCircleOutline'
import IconButton from '@mui/material/IconButton'
import { default as ChangeToClientIcon } from '@mui/icons-material/ExitToApp'
import Typography from '@mui/material/Typography'

import { HelpBox } from '../components/HelpBox'
import { DataTable, FullPageDataTableContainer } from '../components/DataTable'
import { clientStore } from '../state/auth/currentClient'
import { Breadcrumbs } from './Breadcrumbs'
import { Client } from '../../../api/app/clients'
import { ClientsForm } from './ClientsForm'
import { useTranslation } from '../services/i18n'

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
    },
]

export const Clients = () => 
    <Routes>
        <Route path="/" element={<ClientList/>}/>
        <Route path="create/*" element={<ClientsForm/>}/>
        <Route path=":gsr_client/*" element={<ClientsForm/>}/>
    </Routes>

const ClientList = () => {
    const { t } = useTranslation()

    const setClient = (clientId: string) => clientStore.getState().setId(clientId)

    return (
        <FullPageDataTableContainer>
            <Breadcrumbs>
                <Typography color="text.primary">{t('navigation.clients')}</Typography>
            </Breadcrumbs>

            <HelpBox title={<Skeleton animation={false} width={500} />}>
                <Skeleton animation={false} />
                <Skeleton animation={false} />
                <Skeleton animation={false} width={600} />
            </HelpBox>

            <Box mb={3}>
                <Button 
                    to="create"
                    component={Link}
                    startIcon={<AddEntityIcon/>}
                    variant="contained" 
                    color="primary"> 
                    {t('add-new-client')}
                </Button>
            </Box>
            
            <DataTable<Client> 
                getRowId={row => row.gsr_client}
                getEditPath={(record) => `/settings/clients/${record.gsr_client}`}
                endpoint="/clients"
                columns={columns}
                actions={(record) => 
                    <IconButton onClick={() => setClient(record.gsr_client)} size="small">
                        <ChangeToClientIcon fontSize="inherit" />
                    </IconButton>
                }/>
        </FullPageDataTableContainer>
    )
}