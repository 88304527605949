import React, { useState, useMemo } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import debounce from 'lodash/debounce'
import Grid from '@mui/material/Grid'
import { default as MuiList } from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import { default as RemovePermissionIcon } from '@mui/icons-material/RemoveCircleOutline'

import { request } from '../state/api'

type LabelGetter<Item> = (item: Item) => string
type KeyGetter<Item> = (item: Item) => string

export interface IAutoSuggestList<Item> {
    items: Item[],
    endpoint: string,
    requestOption?: {},
    onChange: (items: Item[], affectedItem: Item, reason: 'added'|'removed') => void,
    getItemLabel: LabelGetter<Item>,
    getItemKey: KeyGetter<Item>,
    inputPlaceholder?: string,
    removeTitle?: string,
    renderActions?: (item: Item) => JSX.Element,
    readOnly?: boolean,
}

/**
 * This component renders a list of items. It makes it possible to add new items to the list via an auto suggest input 
 * field, as well as removing existing items from the list. Note that this component will not read nor update the actual 
 * list from the backend as it is controlled - you'll have to pass in the roles via props. However by default the list
 * will use a provided endpoint to load suggestions.
 * 
 * Also note this is typed as a generic, pass in the type of the items for ultimate fun:
 *    <AutoSuggestList<>/>
 * 
 */
export const AutoSuggestList = <Item,>(props: IAutoSuggestList<Item>) => {
    const handleSelection = (item: Item) => 
        // Add the new role to the end of the list.
        props.onChange([ ...props.items, item ], item, 'added')

    const handleRemoval = (item: Item) =>
        // Drop the provided role from the list.
        props.onChange(props.items.filter(i => props.getItemKey(i) !== props.getItemKey(item)), item, 'removed')


    return <React.Fragment>
        <SearchBox
            readOnly={props.readOnly}
            placeholder={props.inputPlaceholder}
            endpoint={props.endpoint}
            requestOption={props.requestOption}
            onSelect={handleSelection} 
            getItemLabel={props.getItemLabel} 
            getItemKey={props.getItemKey}/>

        <List 
            readOnly={props.readOnly}
            items={props.items} 
            onRemove={handleRemoval}
            renderActions={props.renderActions}
            removeTitle={props.removeTitle}
            getItemLabel={props.getItemLabel} 
            getItemKey={props.getItemKey}/>
    </React.Fragment>
}

/**
 * This component takes a list of items via its props and renders them nicely into a list.
 * 
 */
export interface IList<Item> {
    items: Item[], 
    onRemove: (item: Item) => void
    getItemLabel: LabelGetter<Item>,
    getItemKey: KeyGetter<Item>,
    removeTitle?: string,
    renderActions?: (item: Item) => JSX.Element,
    readOnly?: boolean,
}

export const List = <Item,>(props: IList<Item>) => {
    return (
        <MuiList>
            {props.items.map(item => 
                <ListItem
                    key={props.getItemKey(item)}
                    disablePadding
                    secondaryAction={
                        !props.readOnly && (
                            <React.Fragment>
                                {props.renderActions && props?.renderActions(item)}
                                <IconButton
                                    title={props.removeTitle||'Remove item from list'}
                                    onClick={e => props.onRemove(item)}
                                    edge="end"
                                    aria-label="remove"
                                    size="large">
                                    <RemovePermissionIcon />
                                </IconButton>
                            </React.Fragment>
                        )
                    }>
                    <ListItemText id={props.getItemKey(item)} primary={props.getItemLabel(item)} />
                </ListItem>
            )}
        </MuiList>
    );
}

/**
 * This is the auto suggest input box that makes it possible to add new items to the set by simply typing their names.
 * 
 */
export interface ISearchBox<Item> {
    endpoint: string,
    requestOption?: {},
    onSelect: (item: Item) => void,
    getItemLabel: LabelGetter<Item>,
    getItemKey: KeyGetter<Item>,
    placeholder?: string,
    readOnly?: boolean,
}

export const SearchBox = <Item,>(props: ISearchBox<Item>) => {
    const [ open, setOpen ] = useState<boolean>(false) 
    const [ value ] = useState<Item | null>(null)
    const [ inputValue, setInputValue ] = useState('')
    const [ options, setOptions ] = useState<readonly Item[]>([])
    const loadSuggestions = useMemo(() => debounce(async (q: string) => {
        const { data } = await request(props.endpoint, { queryParameters: { q, ...props.requestOption, }})
        setOptions(data)
    }, 200), [props.requestOption, props.endpoint])

    React.useEffect(() => {
        if (!inputValue && !open) {
            setOptions([])
            return
        }

        loadSuggestions(inputValue)
    }, [value, inputValue, loadSuggestions, open])

    const handleChange = (e: any, selectedItem: Item | null) => {
        if (!selectedItem) 
            return

        // Drop the selected item from the listed options right away, clear the input and propagate the changed value.
        // Note that it would probably be better to keep the list of options visible even after selecting a new value
        // because most of the time the user may like to select multiple values from the results. The auto-closing seems
        // to be default behaviour by the MUI component, though. We'll have to look into this at times.
        // @see #GS-81
        setOptions(options.filter(item => props.getItemKey(item) !== props.getItemKey(selectedItem)))
        setInputValue('')
        props.onSelect(selectedItem)
    }

    const renderOption = (optionProps: any, item: Item) =>  
        <li {...optionProps}>
            <Grid container alignItems="center">
                <Grid item xs>{props.getItemLabel(item)}</Grid>
            </Grid>
        </li>

    const renderInput = (params: any) => 
        <TextField
            {...params} 
            margin="dense"
            label={props.placeholder||'Add new item to list'}
            type="text"
            name="name"
            size="small"
            autoComplete='new-password'
            inputProps={{
                ...params.inputProps,
            }} />

    if (props.readOnly) {
        return null
    }

    return <Autocomplete
        autoComplete
        includeInputInList
        filterSelectedOptions
        options={options}
        value={value}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        filterOptions={(x) => x}
        getOptionLabel={props.getItemLabel}
        onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
        onChange={handleChange}
        renderOption={renderOption}
        renderInput={renderInput}/>
}