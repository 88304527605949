import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box'

const API_TOKEN = '1/1202036393552147:1b79f809d938fb9f12845cd7e2f94b20'

export const POCChecker = () => {
    const [percentage, setPercentage] = useState(0)

    useEffect(() => { (async () => {
        const { data: tasks } = await (await fetch(
            'https://app.asana.com/api/1.0/projects/1203146948129412/tasks?opt_fields=memberships.section.name,completed,name,num_subtasks,subtasks.name,subtasks.id,subtasks.completed&limit=100',
            { headers: { 'Authorization': 'Bearer ' + API_TOKEN } }
        )).json()

        let correctedTasks = tasks.map((task: any) => ({ 
            ...task,
            percentage: task.completed || task.memberships[0].section.name === 'Done' 
                ? 100 
                : (task.subtasks.reduce((sum: number, item: any) => sum + Number(item.completed), 0)/task.subtasks.length)*100 || 0
        }))

        setPercentage(Math.round(((correctedTasks.reduce((sum: number, task: any) => sum + task.percentage, 0)/(correctedTasks.length*100))*100)*100)/100)
    })() }, [setPercentage])

    return <Box sx={{ 
        width: '100%', 
        height: '100%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        flexDirection: 'column',
    }}>
        <Box sx={{ fontSize: '3em' }}>We POC yet?</Box>
        <Box sx={{ fontSize: '8em' }}>
            {percentage === 100 && 'OH YEAH 🎉'}
            {percentage !== 100 && 'NO 😔'}
        </Box>
        <Box sx={{ fontSize: '1em', }}>We're at <strong>{percentage}%</strong> currently.</Box>
        <Box sx={{ mt: 5, width: '200px', background: 'rgba(0,0,0,.1)' }}>
            <Box sx={{ height: '5px', width: percentage + '%', background: 'green' }}/>
        </Box>
    </Box>
}