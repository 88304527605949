import React from 'react'
import { styled } from '@mui/material/styles'
import Grid, { GridProps } from '@mui/material/Grid'
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel'
import Typography, { TypographyProps } from '@mui/material/Typography'

export const Form = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: '#fff',
    padding: '10px',
    overflow: 'hidden',
}))

/**
 * We use this fancy wrapper around <Grid/> to add further styling and wrap it inside of a <div/> we can hide.
 * This way we can create a nice form layout without having to repeat a lot of overhead in every form.. instead
 * just call <FormContent/> and you're all set.
 * 
 * Notice how we pass the props to the <Grid/> element, while passing the className from the props only to the outer
 * <div/>. We do this because the className is actually used to attach the custom styles to the element. If we didn't
 * pass the className to the <div/> the styles would be applied to the <Grid/> only. If we were to pass it to both
 * elements (i.e. by pushing the full props to the inner <Grid/>) the styles would be doubled, i.e. paddings and margins
 * would be applied to both.
 * 
 */
export const FormContent = styled(
    ({className, hidden, fullHeight, ...props}: GridProps & { hidden?: boolean, fullHeight?: boolean }) => 
        <div hidden={hidden} className={className}>
            <Grid container alignItems="center" {...props} sx={{ height: fullHeight ? '100%' : '', }}>
                {!hidden && props.children}
            </Grid>
        </div>
    )(({ theme }) => ({
        flex: 1,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        overflowY: 'auto',
        height: '100%',
    })
)

export const Label = styled(
    (props: InputLabelProps) => 
        <Grid item xs={12} sm={12} md={4} sx={{ 
            pr: 2,
            textAlign: 'right',
        }}>
            <InputLabel sx={{ whiteSpace: 'initial' }} {...props}>{props.children}</InputLabel>
        </Grid>
    )(({ theme }) => ({

    })
)

export const Field = styled(
    (props: GridProps) => 
        <Grid item xs={12} sm={12} md={8} {...props}>
            {props.children}
        </Grid>
    )(({ theme }) => ({

    })
)

export const Heading = styled(
    (props: TypographyProps) => <>
        <Grid item xs={12} sm={12} md={4}/>
        <Grid item xs={12} sm={12} md={8}>
            <Typography {...props} variant='h6'>{props.children}</Typography>
        </Grid>
    </>
    )(({ theme }) => ({

    })
)