// This is a polyfill because some crackhead at W3C forgot to correctly implement the iterable protocol in this interface.
// Whoever was it - thank you for that, I hate you.
// https://stackoverflow.com/questions/47017441/how-to-use-array-from-with-a-xpathresult
// @ts-ignore
XPathResult.prototype[Symbol.iterator] = function* () {
    switch (this.resultType) {
        case XPathResult.UNORDERED_NODE_ITERATOR_TYPE:
        case XPathResult.ORDERED_NODE_ITERATOR_TYPE:
            let result;
            while ( (result = this.iterateNext()) != null ) yield result;
            break;
        case XPathResult.UNORDERED_NODE_SNAPSHOT_TYPE:
        case XPathResult.ORDERED_NODE_SNAPSHOT_TYPE:
            for (let i=0; i < this.snapshotLength; i++) yield this.snapshotItem(i);
            break;
        default:
            yield this.singleNodeValue;
            break;
    }
}

export interface FlowEntity {
    source: FlowEntitySource,
    actions: FlowEntityAction[],
}

export interface FlowEntitySource {
    columns: string[],
    schemaName: string,
    entityName: string,
    condition: any,
}

export interface FlowEntityAction {
    type: string,
}

const nsResolver = (element: any) => {
    const nsResolver = element.ownerDocument.createNSResolver(element)
    const defaultNamespace = element.getAttribute('xmlns')

    return (prefix: any) => nsResolver.lookupNamespaceURI(prefix) || defaultNamespace
}

const parser = new DOMParser()

// Shorthand to at least keep the silly type conversion in one place.
const evaluate = (xpath: string, doc: Document, contextNode?: Node|Document): Node[] => 
    [ ...doc.evaluate(xpath, contextNode || doc, nsResolver(doc.documentElement), XPathResult.ANY_TYPE, null) as unknown as Node[] ] 


// This takes a flow structure (XML as string) and tries to fetch all contained entity nodes from it, including
// all relevant data (i.e. the selected columns, actions, yada yada).
export const parseEntities = (flowStructure: string): FlowEntity[] => {
    if (!flowStructure.length) {
        // Can't parse from thin air, duh.
        return []
    }

    const doc: Document = parser.parseFromString(flowStructure, 'application/xml');
 
    return evaluate('//flow/entity', doc).map(entity => parseEntity(entity, doc))
}

export const parseEntity = (entityNode: Node, doc: Document): FlowEntity => {
    const evaluateEntity = (xpath: string) => evaluate(xpath, doc, entityNode)

    const source: FlowEntitySource = {
        columns: [],
        schemaName: '',
        entityName: '',
        condition: null,
    }

    source.columns = evaluateEntity('./source/select/column').map(e => e.textContent || '') || []
    source.schemaName = evaluateEntity('./source/from/schema')[0]?.textContent || ''
    source.entityName = evaluateEntity('./source/from/entity')[0]?.textContent || ''
    source.condition = evaluateEntity('./source/where/expression/@text')[0]?.textContent || ''


    // lol.. how to exclude blank text nodes, cannot remember. If it works, it works.. i guess.
    const actions: FlowEntityAction[] = evaluateEntity('./actions/node()[not(not(text()))]').map(node => ({ type: node.nodeName }))

    return { source, actions, }
}