import React, { useState, useRef } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { default as HelpIcon } from '@mui/icons-material/LiveHelpOutlined'
import { default as ForgotPasswordIcon } from '@mui/icons-material/ChevronRight'
import { styled } from '@mui/material/styles'

import { login, useAuth } from './state/auth'
import { useConfig } from './state/config'
import { useTranslation } from './services/i18n'
import loginBackground from './assets/login-background.svg'

const BuildContainer = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  textAlign: 'center',
  zIndex: 999999,
  color: '#fff',
  fontSize: '12px',
  lineHeight: '2em',
})

export const Login = () => {
  const { t } = useTranslation()

  const usernameRef = useRef<HTMLInputElement>()
  const passwordRef = useRef<HTMLInputElement>()

  const [usernameError, setUsernameError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const isLoading = useAuth(state => state.isLoading())

  const revision = useConfig(config => config.revision)
  const buildTime = useConfig(config => config.buildTime)
  const version = useConfig(config => config.version)
  const environment = useConfig(config => config.environment)

  const handleSubmit = async () => {
    const { current: username } = usernameRef
    const { current: password } = passwordRef

    if (!username || !password) return

    if (!username.value) {
      setUsernameError(t('please-enter-username'))
    }

    if (!password.value) {
      setPasswordError(t('please-enter-password'))
    }

    if (!username.value || !password.value) {
      return
    }

    await login(username.value, password.value)
  }

  const handleUsernameChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { current: username } = usernameRef
    if (!username) return

    if (usernameError && username.value.length) {
      setUsernameError('')
    }

    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handlePasswordChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { current: password } = passwordRef
    if (!password) return

    if (passwordError && password.value.length) {
      setPasswordError('')
    }

    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{ elevation: 1 }}
        open
        BackdropProps={{
          sx: {
            background: 'url(' + loginBackground + ') center center',
            backgroundSize: 'cover',
          },
        }}>
        <Backdrop
          open={isLoading}
          sx={{
            zIndex: 99,
            position: 'absolute',
            background: 'rgba(255,255,255,.2)',
            backdropFilter: 'blur(3px)',
          }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            <Typography variant="h6" component="span">
              Flow.BI ›
            </Typography>{' '}
            {t('please-sign-in')}
          </DialogContentText>
          <TextField
            error={!!usernameError}
            autoFocus
            margin="dense"
            id="username"
            label={t('user')}
            type="email"
            inputRef={usernameRef}
            helperText={usernameError}
            onKeyDown={handleUsernameChange}
            fullWidth
          />
          <TextField
            error={!!passwordError}
            margin="dense"
            id="password"
            label={t('password')}
            type="password"
            inputRef={passwordRef}
            helperText={passwordError}
            onKeyDown={handlePasswordChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#f5f5f5',
            marginTop: '10px',
          }}>
          <Button
            sx={{ opacity: 0.8 }}
            target="_blank"
            href="https://docs.flow.bi"
            startIcon={<HelpIcon />}
            size="small">
            Docs
          </Button>
          <div style={{ flex: '1 0 0' }} />
          {/*<Button onClick={() => {}} size="small">
                    {t('forgot-password')}
                </Button>*/}
          <Button
            onClick={handleSubmit}
            endIcon={<ForgotPasswordIcon />}
            variant="contained"
            color="primary"
            size="medium">
            {t('continue')}
          </Button>
        </DialogActions>
      </Dialog>

      {environment === 'preview' && (
        <BuildContainer>
          <span title={revision + ' @ ' + buildTime}>{version}</span>
        </BuildContainer>
      )}
    </React.Fragment>
  )
}
