import { useCallback, useRef } from 'react'
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter'
import { FlexGridColumn } from '@grapecity/wijmo.react.grid'

import { request } from './state/api'
import { useInstance } from './state/auth/currentInstance'
import { DataGrid } from './components/DataGrid'

export const InstanceOptions = () => {
    const gridInstance = useRef<any>()
    const instanceId = useInstance(instance => instance.gsr_inst)
    
    const processRowUpdate = useCallback(
        async (input: any) => {
            // Note we wrap the row into an array because the endpoint supports updating multiple options
            // at once. For our use case we'll only ever update a single option at once right after it
            // has been touched.
            await request('/instances/' + instanceId + '/options', { method: 'put', jsonBody: [ input ] })
            return input
        },
        [instanceId],
    )

    const fixDefaults = useCallback((grid: any) => {
        grid.collapseGroupsToLevel(0)

        grid.rows.forEach((row: any) => {
            row.isReadOnly = false
        })
    }, [])

    return <>
        <DataGrid
            endpoint={`/instances/${instanceId}/options`}
            processRowUpdate={processRowUpdate}
            FlexGridProps={{ 
                allowAddNew: false,
                initGrid: (grid: any) => gridInstance.current = grid,
                childItemsPath: ['options', 'options', 'options', 'options', 'options', 'options'],
                loadedRows: fixDefaults,
                headersVisibility: 'Column',
                selectionMode: 'Row',
            }}>
            <FlexGridFilter />
            <FlexGridColumn header="Parameter" binding="config_key" width="*" isReadOnly={true}/>
            <FlexGridColumn header="Value" binding="config_value" width="*"/>
            <FlexGridColumn header="Comments" binding="comments" width="*" isReadOnly={true}/>
        </DataGrid>
    </>
}