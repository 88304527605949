import React, { useRef, useState, useMemo, useEffect, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { styled } from '@mui/material/styles'
import ForceGraph3D from '3d-force-graph'
import ForceGraphVR from '3d-force-graph-vr'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import * as THREE from 'three'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import CircularProgress from '@mui/material/CircularProgress'
 
import { request } from '../state/api'
import { useUser } from '../state/auth/currentUser'
import { useTranslation } from '../services/i18n'
import { useInstance } from '../state/auth/currentInstance'

const cube = new THREE.BoxGeometry(5, 5, 5)

type Snapshot = {
    gsr_client: string,
    gsr_sdts: string,
    gsr_inst: string,
    gsr_rsrc: string,
    url: string,
    comments: string,
    tags: string,
}

type Link = {
    source_id: string,
    target_id: string,
}

type Node = {
    id: string,
    gsr_sdts: string,
    label: string,
    icon: string,
    color: string,
}

const Content = styled('div')({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
})

const PaperContainer = styled('div')({
    width: '100%',
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: '#202124',
    borderRadius: '.7em',
})

const Paper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: 'transparent !important',
    '& > div > div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
})

export const History = () => {
    const instanceId = useInstance(instance => instance.gsr_inst)
    const { t } = useTranslation()
    const graphRef = useRef<any>(null)
    const paperRef = useRef<HTMLDivElement>(null)
    const paperContainerRef = useRef<HTMLDivElement>(null)

    const [ currentGraphType, setCurrentGraphType ] = useState<string>()
    const [ currentSnapshotSdts, setCurrentSnapshotSdts ] = useState<string>()

    const [ snapshots, setSnapshots ] = useState<Snapshot[]>([])
    const [ graph_types, setGraphTypes ] = useState<string[]>([])
    const [ dataModel, setDataModel ] = useState<{ nodes: Node[], links: Link[] }>()

    const useVR = useUser(user => user.use_vr_data_model)

    const loadSnapshots = useCallback(async () => {
        const { data: snapshotList } = await request(
            '/data-model/snapshots', 
            { queryParameters: { 
                instanceId, 
                order: 'DESC',
                sort_by: 'gsr_sdts',
            }},
        )
        setSnapshots(snapshotList)

        // If we have no selected snapshot id, automatically select the first in the result.
        if (snapshotList.length && !currentSnapshotSdts) {
            setCurrentSnapshotSdts(snapshotList[0].gsr_sdts)
        }
    }, [currentSnapshotSdts, instanceId])

    const loadGraphTypes = useMemo(() => debounce(async (snapshot_sdts: string) => {
        const { data: graph_types } = await request(
            '/data-model/snapshots/' + snapshot_sdts,
            { queryParameters: {
                instanceId,
            } }
        )

        setGraphTypes(graph_types)

        if (graph_types.length && !currentGraphType) {
            if (graph_types.includes('rdv.logical')) {
                setCurrentGraphType('rdv.logical')
            } else {
                setCurrentGraphType(graph_types[0])
            }
        }
    }, 200), [currentGraphType, instanceId]) 

    const loadData = useCallback(async <T,>(snapshot_sdts: string, graph_type: string, what: 'nodes'|'links', result: T[] = []): Promise<T[]> => {
        const { data: loadedNodes } = await request(
            `/data-model/snapshots/${snapshot_sdts}/${graph_type}/${what}`,
            { queryParameters: {
                instanceId
            }}
        )
        result = [ ...result, ...loadedNodes ] 
        return result
    }, [instanceId])

    const loadDataModel = useMemo(() => debounce(async (snapshot_sdts: string, graph_type: string) => {
        if (!graph_type || !snapshot_sdts) {
            return
        }

        const snapshotContent = { 
            nodes: await loadData<Node>(snapshot_sdts, graph_type, 'nodes'), 
            links: await loadData<Link>(snapshot_sdts, graph_type, 'links'),
        }        

        setDataModel(snapshotContent)
    }, 200), [loadData]) 

    // Load snapshots once we're done rendering.
    useEffect(() => { loadSnapshots() }, [ loadSnapshots ])
    // After the snapshot has been selected, load its diagram types.
    useEffect(() => { 
        currentSnapshotSdts && loadGraphTypes(currentSnapshotSdts) 
    }, [ currentSnapshotSdts, loadGraphTypes ])

    useEffect(() => { 
        if (currentGraphType && currentSnapshotSdts) {
            loadDataModel(currentSnapshotSdts, currentGraphType) 
        }
    }, [ currentSnapshotSdts, currentGraphType, loadDataModel ])

    useEffect(() => {
        if (!graphRef.current && paperRef.current && dataModel) {
            graphRef.current = (useVR ? ForceGraphVR : ForceGraph3D)({
                rendererConfig: {
                    precision: 'lowp',
                    antialias: false,
                    powerPreference: 'high-performance',
                    alpha: false,
                }
            })(paperRef.current)

            if (!useVR) {
                const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1, 1.2, 0.25)
                graphRef.current?.postProcessingComposer().addPass(bloomPass)
            }

            graphRef.current
                .graphData(dataModel)
                

            if (!useVR) {
                graphRef.current
                    .enableNodeDrag(false)
                    .nodeThreeObject((node: Node) => new THREE.Mesh(
                        cube, 
                        new THREE.MeshLambertMaterial({
                            // @ts-ignore
                            color: node.color,
                            transparent: true,
                            opacity: 1,
                        })
                    ))
                    .backgroundColor('#202124')
                    .linkSource('source_id')
                    .linkTarget('target_id')
                    .nodeResolution(1)
                    .linkResolution(1)
                    .nodeVisibility(true)
                    .showNavInfo(false)
                    .linkOpacity(.1)
                    .nodeLabel((d: any) => 
                        `<div><span style="background: #fff; color: #000; padding: 5px; font-size: .7em; font-weight: 600;">${d.label}</span></div>`
                    )
                    .d3Force('center')
            }
        }

        return () => {
            if (graphRef.current) {
                graphRef.current
                    .graphData({ nodes: [], links: [] })

                if (!useVR) {
                    graphRef.current.pauseAnimation()
                }
                graphRef.current = null
            }
        }
    }, [ dataModel, useVR ])

    return <Content>
        <div style={{ position: 'relative' }}>
            <TextField
                sx={{ width: 200 }}
                select
                id="auth-schema"
                label={t('snapshot')}
                margin="dense"
                value={currentSnapshotSdts || ''}
                onChange={e => setCurrentSnapshotSdts(e.target.value)}
                variant="standard">
                {snapshots.map(snapshot => 
                    <MenuItem key={snapshot.gsr_sdts} value={snapshot.gsr_sdts}>{new Date(snapshot.gsr_sdts).toLocaleString()}</MenuItem>  
                )}
            </TextField>
            <TextField
                sx={{ ml: 2, width: 200 }}
                select
                id="auth-schema"
                label={t('graph-type')}
                margin="dense"
                value={currentGraphType || ''}
                onChange={e => setCurrentGraphType(e.target.value)}
                variant="standard">
                {graph_types.map(type => 
                    <MenuItem key={type} value={type}>{type}</MenuItem>  
                )}
            </TextField>
            <span style={{ position: 'absolute', float: 'right', bottom: 0, right: 0, userSelect: 'none' }}>A.M.D.G.</span>

        </div>
        <PaperContainer ref={paperContainerRef}>
            <Paper ref={paperRef}>
                <CircularProgress sx={{ color: '#fff', width: 30, height: 30 }} />
            </Paper>
        </PaperContainer>
    </Content>
}