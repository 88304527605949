export const handleEnterKeyDown = (e: React.KeyboardEvent, callback: () => void) => {
  if (e.key === 'Enter') {
    // Trigger onBlur event for the active element
    // This is needed when input sanitization is performed onBlur
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
    callback()
  }
}
