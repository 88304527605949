import { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { useApiError } from '../state/apiError'
import { useTranslation } from '../services/i18n'
import { useAuth } from '../state/auth'
import { Divider } from '@mui/material'

export const ApiErrorNotification = () => {
  const { t } = useTranslation()
  const error = useApiError(error => error)
  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogDescription, setDialogDescription] = useState<string>('')

  useEffect(() => {
    let title: string = ''
    let description: string = ''

    if (!error.hasError) {
      title = ''
      description = ''
    } else if (error.isBadRequest) {
      title = t('api-error-bad-request-title')
      description = t('api-error-bad-request-description')
    } else if (error.isMissingPermissions) {
      title = t('api-error-missing-permissions-title')
      description = t('api-error-missing-permissions-description')
    } else if (error.isServerError) {
      title = t('api-error-server-error-title')
      description = t('api-error-server-error-description')
    } else if (error.isInternalServerError) {
      title = t('api-error-internal-server-error-title')
      description = t('api-error-internal-server-error-description')
    } else if (error.isServerNotReachable) {
      title = t('api-error-server-unreachable-title')
      description = t('api-error-server-unreachable-description')
    } else if (error.isUnauthorized) {
      title = t('api-error-unauthorized-title')
      description = t('api-error-unauthorized-description')
    } else if (error.isInvalidPassword) {
      title = t('api-error-incorrect-password-title')
      description = t('api-error-incorrect-password-description')
    } else if (error.isNotFound) {
      title = t('api-error-not-found-title')
      description = t('api-error-not-found-description')
    } else if (error.isUserNotFound) {
      title = t('api-error-user-not-found-title')
      description = t('api-error-user-not-found-description')
    } else if (error.isResourceAlreadyExist) {
      title = t('api-error-resource-already-exist-title')
      description = t('api-error-resource-already-exist-description')
    }

    setDialogTitle(title)
    setDialogDescription(description)
  }, [error, t])

  if (!error.hasError()) {
    return <></>
  }

  const handleOK = () => {
    useAuth.setState({ hadError: true }) // this sets isLoading:false
    error.dismiss()
  }

  const serverErrorResponse = useApiError.getState().serverErrorResponse

  return (
    <Dialog
      onClick={() => {}} // prevents default behaviour of closing Dialog when clicked on backdrop
      open={Boolean(error.hasError())}
      disableEscapeKeyDown={!error.isDismissible}
      onClose={e => error.isDismissible && error.clearError()}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText> */}
        <div style={{ marginBottom: '3em' }}>{dialogDescription}</div>

        <Divider />
        <div style={{ marginTop: '1em', marginBottom: '1em' }}>
          {serverErrorResponse && (
            <div style={{ marginTop: '.5em' }}>Server Response: {serverErrorResponse}</div>
          )}
          <div>HTTP status code: {useApiError.getState().statusCode}</div>
        </div>
        <div
          style={{
            marginTop: '1em',
            paddingTop: '.5em',
            borderTop: '1px solid #dedede',
            fontSize: '.9em',
          }}>
          <div>
            {error.method?.toUpperCase()} {error.endpoint}
          </div>
          {error.requestId && (
            <div>
              {t('request-id')}: {error.requestId}
            </div>
          )}
        </div>
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <div style={{ flex: 1 }}>
          {error.type && (
            <Button variant="outlined" href={error.type} target="_blank">
              {t('hints-learn-more')}
            </Button>
          )}
        </div>
        {error.onRetry && (
          <Button variant="contained" onClick={() => error.retry()}>
            {t('retry')}
          </Button>
        )}
        {error.isDismissible && (
          <Button variant="contained" onClick={handleOK}>
            {t('okay')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
