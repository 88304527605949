import React, { useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Link, useParams } from 'react-router-dom'

export type TTab = {
    icon?: React.ReactElement,
    label?: string,
    id?: string,
}

export const FormTabs = (props: { tabs: TTab[], }) => {
    const { tabId } = useParams()
    const [ currentTab, setCurrentTab ] = useState(props.tabs.map(t => t.id).indexOf(tabId || ''))
    useEffect(() => { setCurrentTab(props.tabs.map(t => t.id).indexOf(tabId || '')) }, [ props.tabs, tabId ])

    return <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
        <Tabs 
            value={currentTab}
            sx={{ minHeight: 'auto' }} 
            indicatorColor="secondary"
            onChange={(e, value) => setCurrentTab(value)}>
            {props.tabs.map(tab => 
                <Tab 
                    key={tab.id}
                    sx={{ minHeight: 'auto', textTransform: 'none' }}
                    id={tab.id}
                    icon={tab.icon}  
                    iconPosition="start"
                    label={tab.label}
                    component={Link}
                    to={`../${tab.id}`}/>  
            )}
        </Tabs>
    </Box>
}