import React, { useState, useEffect, useCallback } from 'react'
import { Routes, Route, useParams, useNavigate, Link, Navigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles'

import { Breadcrumbs } from './Breadcrumbs'
import { useTranslation } from '../services/i18n'
import { useSnackbar } from '../components/Snackbars'
import { request } from '../state/api'
import { useClient } from '../state/auth/currentClient'
import { useUser } from '../state/auth/currentUser'
import { ApiKey } from '../../../api/app/apiKeys'
import { AutoSuggestList } from '../components/AutoSuggestList'
import { Permission, PermissionApi } from '../../../api/app/roles'
import { FormButtons } from '../components/FormButtons'
import { FormTabs } from '../components/FormTabs'
import { Form, FormContent, Label, Field } from '../components/Form'
import { FullPageDataTableContainer } from '../components/DataTable'

export const ApiKeyForm = () => 
    <Routes>
        <Route path="/" element={<Navigate to="general" replace/>}/>
        <Route path=":tabId" element={<Content/>}/>
    </Routes>

const KeyContainer = styled('div')(({ theme }) => ({
    fontSize: '1.3em',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontWeight: 500,
    backgroundColor: '#fffadb',
    padding: theme.spacing(1),
    textAlign: 'center',
    flex: 1,
}))

export const Content = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const clientId = useClient(client => client.gsr_client)
    const userName = useUser((user) => user.name)
    const dbUserName= useUser((user) => user.database_user)

    const { apiKeyId: recordId, tabId: currentTab } = useParams() 
    const { showSnackbar } = useSnackbar()
    const [ , setIsLoading ] = useState(false)
    const [ isSaving, setIsSaving ] = useState(false)

    const [ label, setLabel ] = useState<string>('')
    const [ type, setType ] = useState('rest')
    const [ permissions, setPermissions ] = useState<PermissionApi[]>([])

    const [ key, setKey ] = useState<string|undefined>()

    const load = useCallback(async () => {
        setIsLoading(true)
        const apiKey = (await request('/api-keys/' + recordId)).data as ApiKey 
        setLabel(apiKey.label || '')
        setType(apiKey.has_sql_access ? 'sql' : 'rest')
        
        if (apiKey.permissions.length) {
            setPermissions(apiKey.permissions.map((permission: Permission) => permission.id)) 
        } else {
            setPermissions([])
        }

        setIsLoading(false)
    }, [ recordId ])

    useEffect(() => { recordId && load() }, [ recordId, load ])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setIsSaving(true)

        try {
            if (!recordId) {
                const result = await request(
                    `/accounts/me/api-keys`, 
                    { 
                        method: 'post', 
                        jsonBody: {
                            label,
                            has_rest_access: type === 'rest',
                            has_sql_access: type === 'sql',
                            gsr_client: clientId,
                            permissions: permissions.map(permission => ({ id: permission })),
                        }
                    }
                )

                setKey(result.data.key)
            } else {
                // We can only update the label on api keys, nothing else can be changed.
                await request(`/api-keys/${recordId}`, { method: 'put', jsonBody: { label } })
                
                showSnackbar({ message: t('save-success'), severity: 'success' })
                navigate('../../')
            }
        } catch (e) {
            showSnackbar({ message: t('save-error'), severity: 'error' })
        }

        setIsSaving(false)

        return false
    }

    const handleDialogClose = () => {
        setKey(undefined)
        navigate('../../')
        showSnackbar({ message: t('save-success'), severity: 'success' })
    }

    return <FullPageDataTableContainer>
        <Dialog
            disableEscapeKeyDown
            open={Boolean(key)}
            onClose={(e) => false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {t('api-key-dialog-title')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('api-key-dialog-content')}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                    }}>
                        <KeyContainer>{key}</KeyContainer>
                        <IconButton 
                            title={t('copy-to-clipboard')} 
                            onClick={() => key && navigator.clipboard.writeText(key)}>
                            <ContentCopyIcon/>
                        </IconButton>
                    </div>
                    {t('api-key-dialog-hint-prefix', { prefix: key?.split('.')[0] })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleDialogClose} autoFocus>
                    {t('api-key-dialog-confirm')}
                </Button>
            </DialogActions>
        </Dialog>

        <Breadcrumbs>
            <Typography color="text.secondary">{userName}</Typography>
            <Link to="../../"><Typography color="text.secondary">{t('api-keys')}</Typography></Link>
            <Typography color="text.primary">
                {recordId 
                    ? t('navigation.update-resource', { name: label })
                    : t('create-api-key')
                }
            </Typography>
        </Breadcrumbs>
        <Form onSubmit={(e) => handleSubmit(e)}>
            <FormTabs tabs={[
                { id: 'general', label: t('general-information'), icon: <TextSnippetIcon/> },
                { id: 'permissions', label: t('permission-assignment'), icon: <TextSnippetIcon/> },
            ]}/>

            <FormContent hidden={currentTab !== 'general'}>
                <Label htmlFor="label">{t('label')}</Label>
                <Field>
                    <TextField
                        autoFocus
                        id="label"
                        type="text"
                        hiddenLabel
                        value={label}
                        margin="dense"
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={e => setLabel(e.target.value)}/>
                </Field>
                <Label htmlFor="type">{t('api-key-type')}</Label>
                <Field>
                    <TextField
                        select
                        disabled={Boolean(recordId)}
                        id="type"
                        hiddenLabel
                        value={type}
                        margin="dense"
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={e => setType(e.target.value)}>
                        <MenuItem value="rest">
                            {t('rest-api')}
                        </MenuItem>
                        <MenuItem value="sql">
                            {t('sql-api')}
                        </MenuItem>
                    </TextField>
                </Field>
                {type === 'sql' && (<>
                    <Label htmlFor="username">{t('api-key-db-username')}</Label>
                    <Field>
                        <TextField
                            id="username"
                            hiddenLabel
                            value={dbUserName}
                            margin="dense"
                            fullWidth
                            size="small"
                            variant="outlined"/>
                    </Field>
                </>)}
            </FormContent>

            <FormContent hidden={currentTab !== 'permissions'}>
                <Field md={12}>
                    <AutoSuggestList<PermissionApi>
                        readOnly={Boolean(recordId)}
                        items={permissions}
                        endpoint="/accounts/me/permissions"
                        getItemKey={permission => permission}
                        getItemLabel={permission => permission}
                        onChange={permissions => setPermissions(permissions)}
                        inputPlaceholder={t('assign-permission-to-role')}
                        removeTitle={t('remove-permission-from-role')}/>
                </Field>
            </FormContent>

            <FormButtons isSaving={isSaving} />
        </Form>
    </FullPageDataTableContainer>
}